// employee leave actions
import theApiCall from "@/hooks/api/the-api-call"
import {apiResponseHandler} from "@/hooks/api/api-helper-function"

export default {

    async loadLeaveRequestList(context, payload) {
        if (!payload.forceRefresh && !context.getters.shouldUpdateLeaveRequestList) { return }

        const userApiId = (localStorage.userId) ? localStorage.userId : context.getters.userApiId
        const response = await theApiCall(`employees/${userApiId}/leave_requests/`)
        const responseData = await apiResponseHandler(response, 'Could not load your leave requests.', 'Leave requests not found. [404]')

        context.commit('setLeaveRequestList', responseData)
        context.commit('setLastLeaveRequestListFetch', { timestamp: new Date().getTime() })
    },


    async getFutureLeaveBalance(context, payload) {
        let userApiId = (localStorage.userId) ? localStorage.userId : context.getters.userApiId
        if (payload.employeeID) userApiId = payload.employeeID
        const response = await theApiCall(`employees/${userApiId}/future_leave_balances/?date=${payload.date}`)
        const responseData = await apiResponseHandler(response, 'Could not fetch future leave balance.', 'Future leave balance calc not found. [404]')
        return responseData
    },


    // POST a leave request. Initial state will be 'submitted'
    async postLeaveRequestApi(context, leaveRequestObj) {
        const userApiId = (localStorage.userId) ? localStorage.userId : context.getters.userApiId

        // https://developer.mozilla.org/en-US/docs/Web/API/FormData/Using_FormData_Objects
        // The FormData interface provides a way to easily construct a set of key/value pairs representing form fields and their values, which can then be easily sent using the fetch() or XMLHttpRequest.send() method.
        let formData = new FormData()
        formData.append('method', 'POST')
        formData.append('start_date', leaveRequestObj.start_date)
        formData.append('end_date', leaveRequestObj.end_date)
        if (leaveRequestObj.hours) {
            formData.append('hours', leaveRequestObj.hours)
        }
        formData.append('leave_type', leaveRequestObj.leave_type)
        formData.append('notes', leaveRequestObj.notes)
        if (leaveRequestObj.attachment) {
            formData.append('attachment', leaveRequestObj.attachment)
        }
        formData.append('state', leaveRequestObj.state)

        const response = await theApiCall(`employees/${userApiId}/leave_requests/`, 'POST', formData)
        const responseData = await apiResponseHandler(response, 'Could not post your leave request.', 'Leave request list not found. [404]')
        return responseData
    },


    // Used for employee && manager update actions
    // PATCH request mofidying state of original leave request
    async updateLeaveRequestState(context, payload) {

        const requestID = payload.requestID
        const updatedState = payload.updatedState
        const employeeID = payload.employeeID

        // check if for own request or an approvee's request
        let userApiId = context.getters.userApiId
        let isUpdatingOwnRequest = true
        if (employeeID && employeeID !== userApiId) { isUpdatingOwnRequest = false }
        if (payload.employeeID) { userApiId = payload.employeeID }

        let body = JSON.stringify({state: updatedState})
        const response = await theApiCall(`employees/${userApiId}/leave_requests/${requestID}/`, 'PATCH', body, 'application/json')
        const responseData = await apiResponseHandler(response, 'Failed to update leave request.', 'Leave request resource not found. [404]')

        // update store to reflect on front-end for own leave request list
        if (isUpdatingOwnRequest) {
            const leaveObjectIndex = context.getters.leaveRequestList.findIndex(requestObject => requestObject.id == requestID);
            context.commit('updateLeaveRequestState', {leaveObjectIndex: leaveObjectIndex, updatedState: responseData.state})
        }

        // update store for approvee list
        if (context.getters.isLeaveApprover) {
            context.commit('updateLeaveApproveeList', {employeeID: employeeID, requestID: requestID, updatedState: responseData.state})
        }

        return (response.ok) ?  {response, responseData} : null
    }
}