<template>
    <menu-item :class="classList" :is-current="isCurrent">
        <template #title>
            Leave Management
        </template>
        <template #subMenuItems>
            <menu-item-second route-path="/my-team/leave-management" section-id="manager-leave-calendar" v-if="isLeaveApprover">Combined Leave Calendar</menu-item-second>
            <menu-item-second route-path="/my-team/leave-management" section-id="manager-leave-requests" v-if="isLeaveApprover">Leave Requests</menu-item-second>
            <menu-item-second route-path="/my-team/leave-management" section-id="download-leave-report" v-if="isHrAdmin || isHrAdminExFinancials || permissionsCanDownloadLeaveReports">Download Leave Report</menu-item-second>
            <menu-item-second route-path="/my-team/leave-balance-lookup" v-if="(isHrAdmin || isHrAdminExFinancials || isLeaveApprover) && isFutureLeaveBalanceEnabled">Leave Balance Lookup</menu-item-second>
        </template>
    </menu-item>
</template>

<script setup>
    import MenuItem from "@/components/layouts/side-nav/side-nav-components/MenuItem.vue"
    import MenuItemSecond from "@/components/layouts/side-nav/side-nav-components/MenuItemSecond.vue"
    import {computed} from "vue"
    import {useStore} from "vuex"
    const store = useStore()

    const isHrAdmin = store.getters.isHrAdmin
    const isHrAdminExFinancials = store.getters.isHrAdminExFinancials
    const isLeaveApprover = computed(() => store.getters.isLeaveApprover)
    const permissionsCanDownloadLeaveReports = store.getters.permissionsCanDownloadLeaveReports
    
    import {useRoute} from 'vue-router'
    const route = useRoute()
    const isCurrent = computed(() => route.meta.menuParent === 'leave-management')
    const classList = computed(() => {
        return {'current-parent': isCurrent.value }
    })

    const isFutureLeaveBalanceEnabled = computed(() => store.getters.isFutureLeaveBalanceEnabled) // tests if future leave balance is enabled by making a call. Payspace returns an error, making this disabled.
</script>