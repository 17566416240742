<template>
    <div>
        <leave-overlapping-request-modal v-model="showOverlappingRequestModal" :overlapping-request="overlappingRequest" @submit-leave-request="submitLeaveRequestHandler(true, false)"/>
        <leave-show-include-message-modal v-model="showIncludeMessageModal" @submit-leave-request="submitLeaveRequestHandler(false, false)"/>
        
        <div id="leave-interface-2">
            <div class="d-flex justify-content-between flex-wrap flex-md-nowrap">

                <!--calendar-->
                <div class="flex-grow-1 pe-md-4 calendar-cont">
                    <v-date-picker
                        is-expanded
                        v-model="range"
                        mode="date"
                        :model-config="modelConfig"
                        :attributes="attributesComputed"
                        is-range
                        @dayclick="clearNoticeHandler"
                    />
                </div>
                
                <!--note and upload-->
                <div class="note-and-upload-cont flex-grow-1 mb-4 mt-n2 mt-md-0 mb-md-0">
                    <div class="application-note-col">
                        <div class="application-note-cont bg-white rounded">
                            <textarea
                                name="applicationNoteExtraText"
                                rows="4"
                                placeholder="Application note (optional)"
                                maxlength="900"
                                v-model="applicationNote"
                                @input="limitWordsHandler"
                            ></textarea>
                        </div>
                        <p class="text-gray-main fw-500 text-end font-size-11">{{ wordCount }}/100 words</p>
                    </div>
                    
                    <div class="attachment-uploader-col mt-3">
                        <div class="file-upload-interface-container bg-white rounded">
                            <MDBFileUpload
                                :maxFileSize="6"
                                defaultMsg="File to include with your request"
                                @change="fileUploadHandler"
                                ref="fileUploadRef"
                            />
                        </div>
                    </div>
                </div>
                
            </div>
            
            <div>
                <bento-alert color="primary" static dismiss v-model="youHaveSelectedNotice" v-if="futureDaysCalc" icon-type="calendar-tick">
                    <div v-if="futureDaysCalc">
                        You have selected <strong>{{ futureDaysCalc }} days</strong> excluding weekends and public holidays.
                    </div>
                </bento-alert>
            </div>
            
            <div class="d-flex justify-content-between flex-wrap flex-md-nowrap">
                <div
                    v-if="isFutureLeaveBalanceEnabled"
                    class="flex-grow-1 pe-md-4 future-leave-balance-cont order-2 order-md-1"
                >
                    <button-bento color="primary" @click="getFutureLeaveBalanceHandler" class="get-balance-btn" v-if="!balanceDisplayObj">
                        Get future leave balance
                    </button-bento>

                    <div v-if="balanceDisplayObj" class="max-w-360 mb-1 mt-n1">
                        <bento-alert
                            color="info" static dismiss v-model="balanceNotice"
                            @click="clearFutureBalanceObjHandler"
                            class="pe-1"
                        >
                            <div class="fw-500 font-size-16 mb-2">Future Leave Balance</div>
                            <div class="font-size-14">
                                <div class="d-flex mb-1 flex-wrap">
                                    <div class="label">Balance date:</div>
                                    <div class="fw-500">{{ longDateFormat(balanceDisplayObj.date) }}
                                    </div>
                                </div>
                                <div class="d-flex flex-wrap">
                                    <div class="label">Accrued leave:</div>
                                    <div class="fw-500"
                                        :class="{'text-danger': Number(balanceDisplayObj.balance) < 0}">
                                        {{ balanceDisplayObj.balance }} days
                                    </div>
                                </div>
                            </div>
                        </bento-alert>
                    </div>

                    <div class="future-quote-leave-notice mt-3 ps-1">
                        <span class="font-weight-500 text-decoration-underline">Select a specific date</span> to see your future leave balance. This takes into account your leave accrual and future approved leave requests.
                    </div>
                </div>
                
                <div class="half-day-col mb-4 mb-md-0 mt-md-1 flex-grow-1 order-1 order-md-2">
                    <MDBCheckbox label="Half day only" :disabled="!isSingleDateSelected" v-model="halfDay"/>
                    <div class="half-day-leave-notice mt-2">
                        Select a single date to enable half day option. If you want a date range that includes a half day, please submit the half day separately
                    </div>
                </div>
            </div>
        </div>
        
        <!--buttons and confirmation / warning row-->
        <div class="mt-4">
            <div v-if="noSelectionNotice">
                <bento-alert class="mb-3" color="danger" static dismiss v-model="noSelectionNotice">
                    Please select a valid date or date range in the future.
                </bento-alert>
            </div>
            <div v-if="leaveRequestSubmittedNotice">
                <bento-alert class="mb-3" static dismiss v-model="leaveRequestSubmittedNotice" icon-type="success-tick">
                    Leave request submitted.
                </bento-alert>
            </div>
            <div v-if="apiResponseError">
                <bento-alert class="mb-3" color="danger" static dismiss v-model="apiResponseError">
                    {{ apiResponseErrorNotice }}
                </bento-alert>
            </div>
            
            <div class="loading-changes-cont" v-if="isSubmittingRequest">
                <loading-small v-model="isSubmittingRequest" parentSelector=".loading-changes-cont" :loadingText="loadingTextMessage"/>
            </div>
            
            <div class="d-flex justify-content-between align-items-center mt-4">
                <div @click="resetLeaveRequestHandler" class="font-weight-600 font-size-17 cursor-pointer">
                    Reset
                </div>
                <button-bento @click="submitLeaveRequestHandler" button-color="dark">
                    Submit Request
                </button-bento>
            </div>
        </div>
        
        <bento-alert class="mt-4" color="info" static dismiss v-model="useCalendarHelpNotice" v-if="!futureDaysCalc && false">
            Use the calendar to select your leave request dates.
        </bento-alert>
    </div>
</template>

<script setup>
    import {MDBFileUpload} from "mdb-vue-file-upload"
    import LoadingSmall from "@/components/UI/v2/loading/LoadingSmall.vue"
    import BentoAlert from "@/components/UI/v2/alerts/BentoAlert.vue"
    import ButtonBento from "@/components/UI/v2/buttons/ButtonBento.vue"
    import {MDBCheckbox} from "mdb-vue-ui-kit"
    import {ref, watch, computed} from "vue"
    import publicHolidays from "@/data/public-holidays/public-holidays"
    import publicHolidaysFuture from "@/data/public-holidays/public-holidays-future"
    import {isValidArray} from "@/hooks/general/type-and-value-checks"
    import {formatDate, longDateFormat} from "@/hooks/general/date-helpers"
    import {useStore} from "vuex"
    import LeaveOverlappingRequestModal
        from "@/components/benefits-leave-reimbursements/leave/components/calendar-interface/LeaveOverlappingRequestModal.vue"
    import LeaveShowIncludeMessageModal
        from "@/components/benefits-leave-reimbursements/leave/components/calendar-interface/LeaveShowIncludeMessageModal.vue"
    
    const store = useStore()
    const selectedLeaveType = computed(() => store.getters.selectedLeaveType)

    const isFutureLeaveBalanceEnabled = computed(() => store.getters.isFutureLeaveBalanceEnabled)

    // data
    const apiResponseError = ref(false)
    const apiResponseErrorNotice = ref(false)
    
    const modelConfig = ref({
        type: 'string',
        mask: 'YYYY-MM-DD',
    })
    
    const range = ref({
        start: null,
        end: null,
    })

    const clearNoticeHandler = () => {
        apiResponseError.value = false
        apiResponseErrorNotice.value = false
        leaveRequestSubmittedNotice.value = false
        showIncludeMessageModal.value = false
        showOverlappingRequestModal.value = false
        overlappingRequest.value = null
    }

    const isSingleDateSelected = ref(false)
    
    const applicationNote = ref('')
    const uploadedFiles = ref(null)
    const noSelectionNotice = ref(false)
    const isSubmittingRequest = ref(false)
    const loadingTextMessage = ref('')
    const youHaveSelectedNotice = ref(true)
    
    const leaveRequestSubmittedNotice = ref(false)
    const showIncludeMessageModal = ref(false)
    const showOverlappingRequestModal = ref(false)
    const overlappingRequest = ref(null)
    
    const halfDay = ref(false)
    
    // watch : date range selection on calendar
    watch(range, (newDateRange) => {
        balanceDisplayObj.value = null
        noSelectionNotice.value = false
        isSingleDateSelected.value = newDateRange && (newDateRange.start === newDateRange.end)
    })
    
    // computed properties
    const leaveRequestList = computed(() => store.getters.leaveRequestList)
    
    const leaveRequestListFutureSubmittedApproved = computed(() => {
        const currentDate = new Date()
        const futureRequests = leaveRequestList.value.filter(request => {
            const leaveStartDate = new Date(request.start_date)
            return leaveStartDate > currentDate
        })
        return futureRequests.filter(request => request.state === 'submitted' || request.state === 'approved');
    })
    
    const leaveRequestsForThisType = computed(() => {
        if (!isValidArray(leaveRequestList.value)) {
            return false
        }
        const unsorted = leaveRequestList.value
        const sortedByDate = unsorted.sort((a, b) => new Date(a.start_date) - new Date(b.start_date))
        const leaveTypeId = Number(selectedLeaveType.value.id)
        return sortedByDate.filter(request => Number(request.leave_type) === leaveTypeId)
    })
    
    
    const attributesComputed = computed(() => {
        let submittedAndApproved = null
        if (leaveRequestsForThisType.value) {
            submittedAndApproved = leaveRequestsForThisType.value.filter(request => request.state === "submitted" || request.state === "approved")
        }
        
        let attributes = []
        if (submittedAndApproved) {
            attributes = submittedAndApproved.map((request) => ({
                dates: {
                    start: new Date(request.start_date),
                    end: new Date(request.end_date),
                },
                highlight: "teal",
            }))
        }
        
        // today
        attributes.unshift({
            key: 'today',
            bar: {
                color: 'yellow',
                style: {
                    height: '4px',
                    width: '65%',
                },
            },
            dates: new Date(),
        })
        
        // this year and next work annirversary
        const dateOfEmployment = store.getters.employee.date_of_employment
        const givenDate = new Date(dateOfEmployment)
        const month = givenDate.getMonth()  // JavaScript months are 0-based
        const day = givenDate.getDate()
        
        const currentYear = new Date().getFullYear()
        const nextYear = currentYear + 1
        
        const sameDayThisYear = new Date(currentYear, month, day)
        const sameDayNextYear = new Date(nextYear, month, day)
        const dates = [sameDayThisYear, sameDayNextYear]
        dates.forEach(date => {
            attributes.unshift({
                key: "anniversary",
                highlight: "pink",
                dates: date,
                popover: {
                    label: 'Work Anniversary'
                }
            })
        })
        
        attributes.push(...publicHolidays)
        return attributes
    })
    
    /**
     * Calculates the number of future leave days between a start and end date,
     * excluding weekends and public holidays.
     *
     * - It first checks if the `range` object and its `start` and `end` properties are defined.
     * - Converts the `start` and `end` dates to JavaScript Date objects.
     * - Iterates through each day between `start` and `end`, adding them to an array.
     * - Filters out weekends (Saturday and Sunday) from the array.
     * - Filters out public holidays from the array.
     *
     * @returns {number|boolean} The number of future leave days, or `false` if `range` is not properly defined.
     */
    const futureDaysCalc = computed(() => {
        if (!range.value || !range.value.start || !range.value.end) {
            return false
        }
        
        const startDate = new Date(range.value.start)
        const endDate = new Date(range.value.end)
        
        const leaveDaysAll = []
        const dt = startDate
        while (dt <= endDate) {
            leaveDaysAll.push(new Date(dt))
            dt.setDate(dt.getDate() + 1)
        }
        
        // # remove weekends
        const leaveExWeekends = leaveDaysAll.filter(day => day.getDay() !== 6 && day.getDay() !== 0)
        
        // # remove public holidays
        const leaveDaysExWeekendsExHols = leaveExWeekends.filter(day => !publicHolidaysFuture.includes(formatDate(day)))
        const days = leaveDaysExWeekendsExHols.length
        return days
    })


    const fileUploadRef = ref(null);
    const fileUploadHandler = (fileList) => uploadedFiles.value = fileList

    const balanceNotice = ref(true)
    const clearFutureBalanceObjHandler = () => {
        balanceDisplayObj.value = null
        range.value = {
            start: null,
            end: null
        }
    }
    const balanceDisplayObj = ref(null)


    const getFutureLeaveBalanceHandler = async() => {
        balanceDisplayObj.value = null
        noSelectionNotice.value = false
        
        const now = new Date()
        now.setHours(0,0,0,0)
        const startDate = new Date(range.value.start)
        startDate.setHours(0,0,0,0)
        
        if (!range.value || !range.value.start || !range.value.end) {
            noSelectionNotice.value = true
            return
        }
        if (startDate < now) {
            noSelectionNotice.value = true
            return
        }
        
        isSubmittingRequest.value = true
        loadingTextMessage.value = 'Fetching future leave balance...'
        
        let futureLeaveBalanceList
        try {
            futureLeaveBalanceList = await store.dispatch('getFutureLeaveBalance', {date: range.value.start})
        } catch (error) {
            apiResponseError.value = true
            apiResponseErrorNotice.value = error.message
            
            range.value = {
                start: null,
                end: null
            }
            halfDay.value = false
            isSubmittingRequest.value = false
            return
        }
        const futureLeaveBalance = futureLeaveBalanceList.find(balance => balance.id === selectedLeaveType.value.id)
        balanceDisplayObj.value = {
            date: range.value.start,
            balance: futureLeaveBalance.balance,
            name: futureLeaveBalance.name,
        }
        isSubmittingRequest.value = false
    }

    const isHrAdmin = store.getters.isHrAdmin
    const isLeaveApprover = store.getters.isLeaveApprover
    
    const submitLeaveRequestHandler = async (doLeaveMessageCheck = true, doOverlappingRequestCheck = true) => {
        showIncludeMessageModal.value = false
        showOverlappingRequestModal.value = false
        overlappingRequest.value = null
        noSelectionNotice.value = false
        leaveRequestSubmittedNotice.value = false
        
        if (!range.value || !range.value.start || !range.value.end) {
            noSelectionNotice.value = true
            return
        }
        
        // overlapping dates check
        if (doOverlappingRequestCheck) {
            const newRequestStartDate = new Date(range.value.start).getTime()
            const newRequestEndDate = new Date(range.value.end).getTime()
            for (const requestObj of leaveRequestListFutureSubmittedApproved.value) {
                const requestStartDate = new Date(requestObj.start_date).getTime()
                const requestEndDate = new Date(requestObj.end_date).getTime()
                
                if (
                    ((newRequestStartDate >= requestStartDate) && (newRequestStartDate <= requestEndDate)) ||
                    ((newRequestEndDate >= requestStartDate) && (newRequestEndDate <= requestEndDate))
                ) {
                    showOverlappingRequestModal.value = true
                    overlappingRequest.value = requestObj
                    return
                }
            }
        }
        
        // leave message check
        if (doLeaveMessageCheck && applicationNote.value.trim().length < 1) {
            showIncludeMessageModal.value = true
            return
        }
        
        isSubmittingRequest.value = true
        loadingTextMessage.value = 'Submitting leave request...'
        
        let hours = (halfDay.value) ? 4 : null
        if (!isSingleDateSelected.value) {
            hours = null;
        }
        const attachment = (uploadedFiles.value) ? uploadedFiles.value[0] : null
        
        const leaveRequestObject = {
            start_date: range.value.start,
            end_date: range.value.end,
            attachment: attachment,
            hours: hours,
            leave_type: selectedLeaveType.value.id,
            notes: applicationNote.value,
            state: "submitted"
        }
        
        // POST leave request
        let leaveRequestObjectReturned
        try {
            leaveRequestObjectReturned = await store.dispatch('postLeaveRequestApi', leaveRequestObject)
        } catch (error) {
            apiResponseError.value = true
            apiResponseErrorNotice.value = error.message
            range.value = {
                start: null,
                end: null
            }
            applicationNote.value = ''
            fileUploadRef.value.clear()
            isSubmittingRequest.value = false
            uploadedFiles.value = null
            halfDay.value = false
            return
        }
        
        // update vuex with just posted leave request object
        const updatedLeaveRequestList = [
            ...leaveRequestList.value,
            leaveRequestObjectReturned
        ]

        store.commit('setLeaveRequestList', updatedLeaveRequestList)
        
        leaveRequestSubmittedNotice.value = true
        range.value = {
            start: null,
            end: null,
        }
        applicationNote.value = ''
        fileUploadRef.value.clear()
        halfDay.value = false
        isSubmittingRequest.value = false

        // for manager - update approvees requests - without having to do page reload
        if (isLeaveApprover || isHrAdmin) {
            await store.dispatch('loadLeaveApproveeList', {forceRefresh: true})
        }
    }
    
    const resetLeaveRequestHandler = () => {
        range.value = {
            start: null,
            end: null,
        }
        balanceDisplayObj.value = null
        applicationNote.value = ''
        noSelectionNotice.value = false;
    }
    
    const useCalendarHelpNotice = ref(true)

    // text area limit words
    const wordCount = ref(0)
    const maxWords = 100
    const limitWordsHandler = () => {
        const words = applicationNote.value.split(/\s+/);
        wordCount.value = words.length;
        
        if (wordCount.value > maxWords) {
            const trimmedWords = words.slice(0, maxWords)
            applicationNote.value = trimmedWords.join(" ")
            wordCount.value = maxWords
        }
    };
    watch(applicationNote, limitWordsHandler)
</script>

<style scoped lang="scss">
    @import "@/styles/global-scss/variables-and-mixins.scss";
    
    :deep(#leave-interface-2) {
        .vc-container {
            background: transparent;
            border: none;
        }
        .vc-title {
            font-weight: 500;
            font-size: 16px;
        }
        .vc-header {
            padding-bottom: 10px;
        }
        .vc-day {
            min-height: 36px;
        }
    }
    
    .calendar-cont {
        max-width: 420px;
        margin-left: -10px;
    }
    
    .note-and-upload-cont, .half-day-col {
        @include media-breakpoint-up(md) {
            flex: 0 0 300px !important;
        }
    }
    
    .application-note-cont {
        padding: 10px;
        border: 1px solid rgba(102, 102, 102, 0.25);
        textarea {
            width: 100%;
            border: none;
            min-height: 110px !important;
            resize: none;
            overflow-y: hidden;
            font-family: 'Inter', sans-serif !important;
            line-height: 18px;
            border: 0;
            outline: 0;
            background: white;
            padding: 0;
            color: #666666;
            &::placeholder {
                color: #666666;
            }
        }
    }
    
    .file-upload-interface-container {
        padding: 10px;
        border: 1px solid rgba(102, 102, 102, 0.25);
    }
    
    :deep(.file-upload) {
        background-color: #fff;
        height: 100px;
    }
    
    :deep(.file-upload-default-message) {
        color: #666666;
    }
    
    :deep(.file-upload-message) {
        i.fas {
            color: #666666;
        }
    }
    
    .future-leave-balance-cont {
        max-width: 420px;
    }
    .get-balance-btn {
        z-index: 1000;
    }

    .future-quote-leave-notice {
        font-weight: 400;
        font-size: 12px;
        color: #666;
    }

    .label {
        flex: 0 0 112px;
    }
    
    .half-day-col {
        padding-left: 4px;
        :deep(label) {
            color: black;
            font-weight: 500;
            position: relative;
            top: -1px;
        }
    }
    
    .half-day-leave-notice {
        font-weight: 400;
        font-size: 12px;
        color: #666666;
    }
</style>