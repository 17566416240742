<template>
    <div v-if="!showTeamAdminInterface">
        <width-container>
            <bento-alert static color="danger">You do not have permissions to access this page.</bento-alert>
        </width-container>
    </div>
    <div v-else>
        <div v-if="isLoading">
            <loading-full-screen v-model="isLoading" loadingText="Loading employees ..."/>
        </div>
        
        <width-container v-else>
            <h1 class="mb-4 pb-2">
                <span class="d-inline-block me-4">Team Admin</span> <span class="d-inline-block me-3 title-chevron"><img src="@/assets/v2/icons/chevron.svg" alt="Chevron right"/></span> <span class="d-inline-block font-weight-400">Reporting Lines</span>
            </h1>
            
            <!--Employee to update select-->
            <card-general>
                <section-header>Select an employee to update</section-header>
                <MDBSelect
                    ref="employeeSelect"
                    v-model:options="optionsEmployee"
                    v-model:selected="selectedEmployee"
                    filter
                    :preselect="false"
                    label="Employee" size="lg" placeholder="Select employee ..."
                    class="mb-3"
                />
                <reporting-lines-employee-info-block :employee="selectedEmployeeObj" v-if="selectedEmployee && selectedEmployeeObj"/>
                <div v-else class="fw-500 my-3">No employee selected.</div>
            </card-general>
            
            <card-general v-if="!isEnabledLeave && !isEnabledReimbursements">
                <p class="mb-0 font-size-16 fw-500">Approver modules are not enabled.</p>
            </card-general>
            
            <!--Leave approver select-->
            <card-general v-if="isEnabledLeave">
                <section-header>Leave Approver(s)</section-header>
                <p class="font-size-15 mt-n3 mb-4">Select 1 or more leave approvers for <span class="fw-500">{{ nameAndPreferredName(selectedEmployeeObj) }}</span></p>
                <MDBSelect
                    ref="leaveApproverSelect"
                    v-model:options="optionsLeaveApprovers"
                    v-model:selected="selectedLeaveApprovers"
                    @change="leaveApproverSelectedHandler"
                    filter
                    :preselect="false"
                    :selectAll="false"
                    multiple
                    :displayedLabels="3"
                    optionsSelectedLabel="approvers selected"
                    label="Leave Approver" size="lg" placeholder="Select leave approver ..." class="mb-3"
                />

                <div class="loading-changes-cont" v-if="isSavingLeaveApprover">
                    <loading-small v-model="isSavingLeaveApprover" parentSelector=".loading-changes-cont" loadingText="Updating leave approvers"/>
                </div>
                <div v-else>
                    <div v-if="isValidArray(selectedLeaveApproversArr)">
                        <template v-for="employee in selectedLeaveApproversArr" :key="employee.id">
                            <reporting-lines-approver-info-block :employeeID="employee.id" class="my-3" @remove-approver="removeLeaveApprover"/>
                        </template>
                    </div>
                    <div v-else>
                        <bento-alert static color="danger">No leave approver set. Please select an approver before saving changes.</bento-alert>
                    </div>
                </div>
                
                <div v-if="leaveApproverUpdatedNotice">
                    <bento-alert class="mb-3" static dismiss v-model="leaveApproverUpdatedNotice">
                        Leave approver(s) updated.
                    </bento-alert>
                </div>
                <div v-if="leaveApiResponseError">
                    <bento-alert class="mb-3" color="danger" static dismiss v-model="leaveApiResponseError">
                        {{ apiResponseErrorNotice }}xxx
                    </bento-alert>
                </div>
                
                <div class="d-flex align-items-center justify-content-between mt-3">
                    <div class="cursor-pointer fw-500 font-size-16 me-5" @click="cancelLeaveApproverChangesHandler" v-if="haveLeaveApproversChanged">Cancel</div>
                    <div v-else>&nbsp;</div>
                    <button-bento button-color="dark" size="sm" v-if="haveLeaveApproversChanged && isValidArray(selectedLeaveApproversArr)" :disabled="!haveLeaveApproversChanged || !isValidArray(selectedLeaveApproversArr)" @click="updateLeaveApproverHandler">Save Changes</button-bento>
                </div>
            </card-general>
            
            <!--Reimbursement approver select-->
            <card-general v-if="isEnabledReimbursements">
                <section-header>Reimbursement Approver(s)</section-header>
                <p class="font-size-15 mt-n3 mb-4">Select 1 or more reimbursement approvers for <span class="fw-500">{{ nameAndPreferredName(selectedEmployeeObj) }}</span></p>
                <MDBSelect
                    ref="reimbursementApproverSelect"
                    v-model:options="optionsReimbursementApprovers"
                    v-model:selected="selectedReimbursementApprovers"
                    @change="reimbursementApproverSelectedHandler"
                    filter
                    :preselect="false"
                    :selectAll="false"
                    multiple
                    :displayedLabels="3"
                    optionsSelectedLabel="approvers selected"
                    label="Leave Approver" size="lg" placeholder="Select leave approver ..." class="mb-3"
                />
                
                <div class="loading-changes-cont" v-if="isSavingReimbursementApprover">
                    <loading-small v-model="isSavingReimbursementApprover" parentSelector=".loading-changes-cont" loadingText="Updating reimbursement approvers"/>
                </div>
                <div v-else>
                    <div v-if="isValidArray(selectedReimbursementApproversArr)">
                        <template v-for="employee in selectedReimbursementApproversArr" :key="employee.id">
                            <reporting-lines-approver-info-block :employeeID="employee.id" class="my-3" @remove-approver="removeReimbursementApprover"/>
                        </template>
                    </div>
                    <div v-else>
                        <bento-alert static color="danger">No reimbursement approver set. Please select an approver before saving changes.</bento-alert>
                    </div>
                </div>
                
                <div v-if="reimbursementApproverUpdatedNotice">
                    <bento-alert class="mb-3" static dismiss v-model="reimbursementApproverUpdatedNotice">
                        Reimbursement approver(s) updated.
                    </bento-alert>
                </div>
                <div v-if="reimbursementApiResponseError">
                    <bento-alert class="mb-3" color="danger" static dismiss v-model="reimbursementApiResponseError">
                        {{ apiResponseErrorNotice }}
                    </bento-alert>
                </div>
                
                <div class="d-flex align-items-center justify-content-between mt-3">
                    <div class="cursor-pointer fw-500 font-size-16 me-5" @click="cancelReimbursementApproverChangesHandler" v-if="haveReimbursementApproversChanged">Cancel</div>
                    <div v-else>&nbsp;</div>
                    <button-bento button-color="dark" size="sm" v-if="haveReimbursementApproversChanged && isValidArray(selectedReimbursementApproversArr)" :disabled="!haveReimbursementApproversChanged || !isValidArray(selectedReimbursementApproversArr)" @click="updateReimbursementApproverHandler">Save Changes</button-bento>
                </div>
            </card-general>
            
            <card-general v-if="false">
                selectedEmployee: {{ selectedEmployee }}
            </card-general>
            
            <footer-contact-help-card/>
        </width-container>
    </div>
</template>

<script setup>
    import WidthContainer from "@/components/UI/v2/containers-cards-headers/WidthContainer"
    import {isEqual, sortBy} from 'lodash'
    import {ref, computed} from "vue"
    import {useStore} from "vuex"
    import BentoAlert from "@/components/UI/v2/alerts/BentoAlert"
    import LoadingFullScreen from "@/components/UI/v2/loading/LoadingFullScreen";
    import CardGeneral from "@/components/UI/v2/containers-cards-headers/CardGeneral.vue"
    import SectionHeader from "@/components/UI/v2/containers-cards-headers/SectionHeader.vue"
    import {loadHrManagerData} from "@/hooks/api/api-dispatch-calls"
    import {MDBSelect} from "mdb-vue-ui-kit"
    import ReportingLinesEmployeeInfoBlock from "@/components/manager/team-admin/reporting-lines/ReportingLinesEmployeeInfoBlock.vue"
    import ReportingLinesApproverInfoBlock from "@/components/manager/team-admin/reporting-lines/ReportingLinesApproverInfoBlock.vue"
    import {convertStringToArray} from "@/hooks/general/type-converters"
    import {isValidArray} from "@/hooks/general/type-and-value-checks"
    import ButtonBento from "@/components/UI/v2/buttons/ButtonBento.vue"
    import LoadingSmall from "@/components/UI/v2/loading/LoadingSmall.vue"
    import FooterContactHelpCard from "@/components/layouts/footer/FooterContactHelpCard.vue"
    import {nameAndPreferredName} from "@/hooks/general/employee-info-utilities"
    
    const store = useStore()
    
    const isEnabledReimbursements = store.getters.isEnabledReimbursements
    const isEnabledLeave = store.getters.isEnabledLeave
    
    
    const loggedInEmployee = store.getters.employee
    const showTeamAdminInterface = store.getters.showTeamAdminInterface
    
    const selectedEmployee = ref(null)
    
    // 1. employee select
    const employeeSelect = ref(null) // can use methods on it
    const employeeList = computed(() => store.getters.employeeList)
    
    const optionsEmployee = computed(() => {
        const arr = []
        for (const employee of employeeList.value) {
            if (!employee.is_active || !employee.is_currently_employed) continue
            let isSelected = (employee.id == selectedEmployee.value)
            if (!selectedEmployee.value) {
                isSelected = (employee.id == loggedInEmployee.id)
            }
            arr.push({
                selected: isSelected,
                text: `${employee.user.first_name} ${employee.user.last_name}`,
                value: Number(employee.id)
            })
        }
        return arr
    })
    
    
    const selectedEmployeeObj = computed(() => employeeList.value.find(employee => employee.id === selectedEmployee.value))
    const selectedEmployeeLeaveApprovers = computed(() => selectedEmployeeObj.value?.leave_approvers?.map(approver => approver.id) || null)
    const selectedEmployeeReimbursementApprovers = computed(() => selectedEmployeeObj.value?.reimbursement_approvers?.map(approver => approver.id) || null)
    
    
    // 2.A. leave approver select
    const leaveApproverSelect = ref(null) // can use methods on it
    const optionsLeaveApprovers = computed(() => {
        const arr = []
        for (const employee of employeeList.value) {
            if (!employee.is_active || !employee.is_currently_employed) continue
            const isSelected = (selectedEmployeeLeaveApprovers.value?.includes(employee.id) || false)
            arr.push({
                selected: isSelected,
                text: `${employee.user.first_name} ${employee.user.last_name}`,
                value: Number(employee.id)
            });
        }
        return arr
    })
    
    const selectedLeaveApprovers = ref("") // this is a string
    const selectedLeaveApproversArr = computed(() => {
        const selectedIDs = convertStringToArray(selectedLeaveApprovers.value)
        if (!selectedIDs) return false
        return employeeList.value.filter(employee => selectedIDs.includes(employee.id))
    })
    const selectedLeaveApproversArrIDs = computed(() => selectedLeaveApproversArr.value.map(employee => employee.id))
    
    const leaveApproverSelectedHandler = () => {
        apiResponseErrorNotice.value = false
        leaveApiResponseError.value = false
        leaveApproverUpdatedNotice.value = false
        if (leaveApproverSelect.value) {
            leaveApproverSelect.value.close()
        }
    }
    
    const cancelLeaveApproverChangesHandler = () => {
        if (leaveApproverSelect.value) {
            leaveApproverSelect.value.setValue(selectedEmployeeLeaveApprovers.value)
        }
    }
    
    const removeLeaveApprover = (idToRemove) => {
        apiResponseErrorNotice.value = false
        leaveApiResponseError.value = false
        leaveApproverUpdatedNotice.value = false
        if (leaveApproverSelect.value) {
            leaveApproverSelect.value.setValue(selectedLeaveApproversArrIDs.value.filter(id => id !== idToRemove))
        }
    }
    
    const haveLeaveApproversChanged = computed(() => {
        return !isEqual(sortBy(convertStringToArray(selectedLeaveApprovers.value), ['id']), sortBy(selectedEmployeeLeaveApprovers.value, ['id']))
    })
    
    
    // 2.B. reimbursement approver select
    const reimbursementApproverSelect = ref(null) // can use methods on it
    const optionsReimbursementApprovers = computed(() => {
        const arr = []
        for (const employee of employeeList.value) {
            if (!employee.is_active || !employee.is_currently_employed) continue
            const isSelected = (selectedEmployeeReimbursementApprovers.value?.includes(employee.id) || false)
            arr.push({
                selected: isSelected,
                text: `${employee.user.first_name} ${employee.user.last_name}`,
                value: Number(employee.id)
            });
        }
        return arr
    })
    
    const selectedReimbursementApprovers = ref("") // this is a string
    const selectedReimbursementApproversArr = computed(() => {
        const selectedIDs = convertStringToArray(selectedReimbursementApprovers.value)
        if (!selectedIDs) return false
        return employeeList.value.filter(employee => selectedIDs.includes(employee.id))
    })
    const selectedReimbursementApproversArrIDs = computed(() => selectedReimbursementApproversArr.value.map(employee => employee.id))
    
    const reimbursementApproverSelectedHandler = () => {
        reimbursementApiResponseError.value = false
        apiResponseErrorNotice.value = false
        reimbursementApproverUpdatedNotice.value = false
        if (reimbursementApproverSelect.value) {
            reimbursementApproverSelect.value.close()
        }
    }
    
    const cancelReimbursementApproverChangesHandler = () => {
        if (reimbursementApproverSelect.value) {
            reimbursementApproverSelect.value.setValue(selectedEmployeeReimbursementApprovers.value)
        }
    }
    
    const removeReimbursementApprover = (idToRemove) => {
        reimbursementApiResponseError.value = false
        apiResponseErrorNotice.value = false
        reimbursementApproverUpdatedNotice.value = false
        if (reimbursementApproverSelect.value) {
            reimbursementApproverSelect.value.setValue(selectedReimbursementApproversArrIDs.value.filter(id => id !== idToRemove))
        }
    }
    
    const haveReimbursementApproversChanged = computed(() => {
        return !isEqual(sortBy(convertStringToArray(selectedReimbursementApprovers.value), ['id']), sortBy(selectedEmployeeReimbursementApprovers.value, ['id']))
    })
    
    
    // error handling api response
    const leaveApiResponseError = ref(false)
    const reimbursementApiResponseError = ref(false)
    const apiResponseErrorNotice = ref(false)
    
    
    // 3.A. update leave approver
    const isSavingLeaveApprover = ref(false)
    const leaveApproverUpdatedNotice = ref(null)
    const updateLeaveApproverHandler = async () => {
        isSavingLeaveApprover.value = true
        leaveApproverUpdatedNotice.value = false
        apiResponseErrorNotice.value = false
        leaveApiResponseError.value = false
        
        const leaveApprovers = convertStringToArray(selectedLeaveApprovers.value).map(item => ({id: item}))
        
        const body = JSON.stringify({
            leave_approvers: leaveApprovers,
        })
        const payload = {
            employeeToUpdate: selectedEmployee.value,
            body: body,
        }
        
        let response, responseData
        try {
            const result = await store.dispatch('updateLeaveOrReimbursementApprover', payload)
            response = result.response
            responseData = result.responseData
        } catch (error) {
            cancelLeaveApproverChangesHandler()
            leaveApiResponseError.value = true
            apiResponseErrorNotice.value = error.message
        }

        if (response?.ok && response?.status === 200) {
            let updatedEmployeeList = employeeList.value.map(employee => employee.id === responseData.id ? responseData : employee)
            store.commit('setEmployeeList', updatedEmployeeList)
            leaveApproverUpdatedNotice.value = true
        }
        
        isSavingLeaveApprover.value = false
    }
    
    
    // 3.B. update reimbursement approver
    const isSavingReimbursementApprover = ref(false)
    const reimbursementApproverUpdatedNotice = ref(null)
    const updateReimbursementApproverHandler = async () => {
        isSavingReimbursementApprover.value = true
        reimbursementApproverUpdatedNotice.value = false
        reimbursementApiResponseError.value = false
        apiResponseErrorNotice.value = false
        
        const reimbursementApprovers = convertStringToArray(selectedReimbursementApprovers.value).map(item => ({id: item}))
        
        const body = JSON.stringify({
            reimbursement_approvers: reimbursementApprovers,
        })
        const payload = {
            employeeToUpdate: selectedEmployee.value,
            body: body,
        }
        
        let response, responseData
        try {
            const result = await store.dispatch('updateLeaveOrReimbursementApprover', payload)
            response = result.response
            responseData = result.responseData
        } catch (error) {
            cancelReimbursementApproverChangesHandler()
            reimbursementApiResponseError.value = true
            apiResponseErrorNotice.value = error.message
        }
        
        if (response?.ok && response?.status === 200) {
            let updatedEmployeeList = employeeList.value.map(employee => employee.id === responseData.id ? responseData : employee)
            store.commit('setEmployeeList', updatedEmployeeList)
            reimbursementApproverUpdatedNotice.value = true
        }
        isSavingReimbursementApprover.value = false
    }
    
    
    // On component creation / load
    const isLoading = ref(false)
    const onCreated = async () => {
        isLoading.value = true
        await loadHrManagerData()
        isLoading.value = false
    }
    onCreated()
    
    
</script>