<template>
    <card-general class="green pb-4">
        <section-header class="black-text">Retirement Products</section-header>

        <div class="d-flex justify-content-between mt-n3">
            <div class="pe-md-5 me--md-5 flex-grow-1 paras-cont gradient-green-text-dark">

                <div v-if="hasRetirementFundContract" class="mt-2 mb-3">
                    <div class="fw-600 font-size-16">Current Plan</div>
                    <ul class="mb-0 list-unstyled font-weight-500 changes-list gradient-green-text-light">
                        <li v-for="change in textSummaryOfCurrentPlanRetirement" :key="change" v-html="change"></li>
                    </ul>
                </div>
                <div v-if="areRetirementDraftChangesDifferentToCurrent || areRetirementChangesSubmitted" class="mt-2 mb-3">
                    <div class="fw-600 font-size-16" v-if="areRetirementDraftChangesDifferentToCurrent">Saved Draft Plan</div>
                    <div class="fw-600 font-size-16" v-if="areRetirementChangesSubmitted">Submitted Plan</div>
                    <ul class="mb-0 mt-1 list-unstyled font-weight-500 gradient-green-text-light">
                        <li v-for="change in textSummaryOfChangesRetirement" :key="change" v-html="change"></li>
                    </ul>
                </div>

                <p class="gradient-green-text-light font-weight-600 mt-3">
                    <span v-if="!hasRetirementFundContract">You do not currently have an RA product.<br></span>
                    <span v-if="hasRetirementFundContract">Use the calculator to determine whether you are on track to meet your retirement savings goal.</span>
                    <span v-if="hasRetirementFundContract && !hasRetirementFundContractPlanned"><br>You do not have a draft or submitted RA application.</span>
                    <span v-if="!hasRetirementFundContract && !hasRetirementFundContractPlanned">You do not currently have an RA, or a draft or submitted RA application.<br></span>
                    <span v-if="!hasRetirementFundContract">You can explore some options we have for you, or use the calculator to get an idea of how much you should be saving towards retirement.</span>
                </p>

            </div>
            <div class="img-col d-none d-md-block mt-n3">
                <img src="@/assets/v2/icons/illustrations/swirling-planets.svg"/>
            </div>
        </div>

        <div v-if="false">
            <div>employeeRetirementFundContractList: <pre>{{ employeeRetirementFundContractList }}</pre></div>
            <div>temp: <pre>{{ tempSelectedRetirementFundContractObj }}</pre></div>
            <div>draft: {{ retirementFundContractObjDraft }}</div>
            <div>submitted: {{ retirementFundContractObjSubmitted }}</div>
            <div>calculator: {{ calculatorRetirementContract }}</div>
            <div>calculator: {{ calculatorRetirementContract }}</div>
        </div>

        <div class="mt-3 mt-md-0" v-if="false">
            <button-bento color="dark">
                <span>Explore product options</span> <img src="@/assets/v2/icons/arrows/arrow-right-white.svg" class="arrow"/>
            </button-bento>
        </div>
    </card-general>
</template>

<script setup>
    import CardGeneral from "@/components/UI/v2/containers-cards-headers/CardGeneral.vue"
    import SectionHeader from "@/components/UI/v2/containers-cards-headers/SectionHeader.vue"
    import ButtonBento from "@/components/UI/v2/buttons/ButtonBento.vue"
    import {useStore} from "vuex"
    import {computed} from "vue"

    const store = useStore()

    const hasRetirementFundContract = computed(() => store.getters.hasRetirementFundContract)
    const hasRetirementFundContractPlanned = computed(() => store.getters.hasRetirementFundContractPlanned)
    const textSummaryOfCurrentPlanRetirement = computed(() => store.getters.textSummaryOfCurrentPlanRetirement)

    const areRetirementDraftChangesDifferentToCurrent = computed(() => store.getters.areRetirementDraftChangesDifferentToCurrent)
    const areRetirementChangesSubmitted = computed(() => store.getters.areRetirementChangesSubmitted)
    const textSummaryOfChangesRetirement = computed(() => store.getters.textSummaryOfChangesRetirement)


    // for testing
    const employeeRetirementFundContractList = computed(() => store.getters.employeeRetirementFundContractList)

    const tempSelectedRetirementFundContractObj = computed(() => store.getters.tempSelectedRetirementFundContractObj)
    const retirementFundContractObjDraft = computed(() => store.getters.retirementFundContractObjDraft)
    const retirementFundContractObjSubmitted = computed(() => store.getters.retirementFundContractObjSubmitted)
    const calculatorRetirementContract = computed(() => store.getters.calculatorRetirementContract)
</script>

<style scoped lang="scss">
    @import "@/styles/global-scss/variables-and-mixins.scss";

    ul {
        line-height: 1.9;
        :deep(li span) {
            font-weight: 600;
            color: $gradient-green-text-dark;
        }
    }

    .paras-cont {
        max-width: 700px;
    }

    .first-para {
        margin-bottom: 4px;
    }

    .img-col {
        flex: 0 0 100px;
        img {
            display: flex;
        }
    }

    .arrow {
        margin-left: 10px;
        margin-bottom: 2px;
        width: 16px;
    }

    :deep(.changes-list) {
        span {
            font-weight: 600;
            @extend .gradient-green-text-dark;
        }
    }
</style>