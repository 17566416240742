// UI state module

import mutations from './mutations'
import actions from './actions'
import getters from './getters'

export default {
    state() {
        return {
            // modals
            showRemSummaryModal: false,

            // rem changes combined
            showRemDraftModalCombined: false,
            showRemSubmittedModalCombined: false,

            // medical
            showRemDraftModalMedical: false,
            showConfirmMedicalAidChangesModal: false,

            // retirement
            showRemDraftModalRetirement: false,
            showConfirmRetirementFundChangesModal: false,

            // side nav
            isSideNav: false,
            isSideNavSlim: false,

            // employee directory
            isListView: true,

            // app error management
            showApiErrorModal: false,
            apiErrorModalTitle: 'Something went wrong',
            apiErrorMessage: null,

            // leave
            selectedLeaveView: 'calendar',
            selectedLeaveType: null,
            isFutureLeaveBalanceEnabled: false,

            // retirement
            selectedRetirementView: 'retirement-calculator', // 'retirement-calculator' or 'retirement-investment-options'
            currentRetirementWizardStep: 1, // can't use nested routes for this wizard, so we need to track the step here (we are already using nested route for content card)
        }
    },

    mutations,
    actions,
    getters,
}
