export default function useGetLeaveTypesFromApproveeList(approveeList) {
    const leaveTypeList = []

    approveeList.forEach(person => {
        person.leave_balances.forEach(balance => {
            const typeExists = leaveTypeList.some(type => type.id === balance.type.id)
            if (!typeExists) {
                leaveTypeList.push(balance.type)
            }
        })
    })

    return leaveTypeList
        .filter(type => !type.name.toLowerCase().includes('unpaid'))
        .sort((a, b) => a.name.localeCompare(b.name))
}