<template>
    <div>
        <loading-full-screen v-model="isUpdatingPersonalInfo" loadingText="Updating your details ..."/>
        
        <MDBRow tag="form" class="g-2 needs-validation" novalidate @submit.prevent="checkForm">
            <MDBCol md="12">
                <card-general class="col-12 d-flex align-items-stretch py-0 mt-0">
                    <div
                        @click="personalProfileCollapse = !personalProfileCollapse"
                        class="d-flex align-items-center justify-content-between page-title-and-buttons-cont anti-card-general-padding py-4 cursor-pointer"
                        :class="{'top-border-only green': personalProfileCollapse}"
                    >
                        <div>
                            <page-header class="page-title mb-0">Employee Profile</page-header>
                            <div class="fw-500">Personal and contact details.</div>
                        </div>
                        <div class="font-size-17">
                            <i class="fas fa-chevron-up" v-if="personalProfileCollapse"></i> <i class="fas fa-chevron-down" v-else></i>
                        </div>
                    </div>
                    
                    <div v-if="incompleteAlert">
                        <bento-alert color="danger" class="mb-2" static v-model="incompleteAlert" v-if="incompleteAlert">Please complete all required fields below with valid entries.</bento-alert>
                    </div>
                    
                    <MDBCollapse v-model="personalProfileCollapse">
                        <div class="row top-content-row mt-4 pt-2">
                            
                            <div class="name-and-title-col d-flex">
                                <div class="name-and-title-card flex-grow-1 my-0">
                                    <div>
                                        <h3 class="mb-1">{{ fullName }}</h3>
                                        <h3 v-if="preferredName">({{ preferredName }})</h3>
                                        <h4 class="job-title mt-3 mb-1" v-if="jobTitle">{{ jobTitle }}</h4>
                                        
                                        <div v-if="!isEditing && !selfDescription" class="cursor-pointer self-description font-weight-500" @click="editHandler">
                                            <img class="edit-pencil" src="@/assets/v2/icons/edit/edit-pencil-solid-green.svg" alt="Edit"/> Include a sentence about yourself ...
                                        </div>
                                        
                                        <div v-if="!isEditing" class="self-description font-weight-500">{{ selfDescription }}</div>
                                        
                                        <profile-info-card-text-area-edit
                                            class="mt-3" v-if="isEditing" v-model="selfDescription" :input-value="selfDescription" label="Sentence about yourself" :full-width="true"
                                            :max-length-value="400" :is-required="false"
                                            invalid-feedback="Please provide a sentence about yourself"
                                        />
                                    </div>
                                </div>
                            </div>
                            
                            <div class="profile-pic-image-col bento-border-radius pb-3">
                                <div>
                                    <div
                                        class="p-0 position-relative my-0 flex-grow-1 profile-pic-uploader-cont bento-border-radius cursor-pointer"
                                        :style="`background-image: url(${profilePic})`"
                                        @click="showFileUploader = true"
                                    >
                                        <MDBFileUpload
                                            v-if="showFileUploader"
                                            ref="fileUploadRef"
                                            @change="fileUploadHandler" disabledRemoveBtn
                                            defaultMsg="Click or drag to update image. Square works best." previewMsg="Click to update image. Square works best."
                                            accept="image/*" :maxFileSize="5" :maxFilesQuantity="1"
                                            class="text-center w-100"
                                        />
                                    </div>
                                </div>
                                <div class="mt-2 d-flex justify-content-end align-items-center" v-if="showFileUploader || isProfilePicChanged">
                                    <div v-if="showFileUploader" @click="cancelAndClearFileUploadHandler" class="mx-2 fw-500 cursor-pointer">Cancel</div>
                                    <button-bento button-color="primary" v-if="isProfilePicChanged" size="sm" @click="updatePersonalInfo">Save image</button-bento>
                                </div>
                            </div>
                        </div>
                        
                        <section-header class="mt-n2">Contact Details</section-header>
                        <div class="mt-n4">
                            <div class="row g-3">
                                <profile-info-card v-if="!isEditing" title="Cell Number" :value="cellNumber"/>
                                <profile-info-card-input-edit
                                    v-else v-model="cellNumber" :input-value="cellNumber" label="Cell Phone Number"
                                    invalid-feedback="Please provide your phone number"
                                />
                                
                                <profile-info-card v-if="!isEditing" title="Preferred Name" :value="preferredName"/>
                                <profile-info-card-input-edit v-else v-model="preferredName" :input-value="preferredName" label="Preferred Name" :is-required="false"/>

                                <profile-info-card v-if="isBentoDemoCompany" title="Email" value="example@email.com" :editable="false"/>
                                <profile-info-card v-else  title="Email" :value="email" :editable="false"/>

                                <profile-info-card title="ID Number" :value="idNumber" :editable="false"/>
                                
                                <profile-info-card title="Emergency Contact" :value="joinedOneLineEmergencyContact" :full-width="true"/>
                                <div v-if="isEditing" class="row g-3 mt-1">
                                    <profile-info-card-input-edit
                                        v-model="ecName" :input-value="ecName" label="Emergency Contact Name" :full-width="true"
                                        invalid-feedback="Please provide an emergency contact"
                                    />
                                    <profile-info-card-input-edit
                                        v-model="ecRelationship" :input-value="ecRelationship" label="Emergency Contact Relationship"
                                        invalid-feedback="Please provide emergency contact relationship"
                                    />
                                    <profile-info-card-input-edit
                                        v-model="ecCellNumber" :input-value="ecCellNumber" label="Emergency Contact Cell Number"
                                        invalid-feedback="Please provide emergency contact cell phone number"
                                    />
                                </div>
                            </div>
                        </div>
                        
                        <section-header class="mt-5">Home Address</section-header>
                        <div class="mt-n2 pb-5">
                            <profile-info-card title="Address" :value="joinedOneLineAddress" :full-width="true"/>
                            <div class="row g-3 mt-1" v-if="isEditing">
                                <profile-info-card-input-edit v-model="unitNumber" :input-value="unitNumber" label="Unit Number" :is-required="false"/>
                                <profile-info-card-input-edit v-model="complex" :input-value="complex" label="Complex" :is-required="false"/>
                                <profile-info-card-input-edit v-model="streetNumber" :input-value="streetNumber" label="Street Number" invalid-feedback="Please provide a street number"/>
                                <profile-info-card-input-edit v-model="street" :input-value="street" label="Street" invalid-feedback="Please provide a street name"/>
                                <profile-info-card-input-edit v-model="suburb" :input-value="suburb" label="Suburb" invalid-feedback="Please provide a suburb"/>
                                <profile-info-card-input-edit v-model="city" :input-value="city" label="City" invalid-feedback="Please provide a city"/>
                                <profile-info-card-input-edit v-model="postalCode" :input-value="postalCode" label="Postal Code" invalid-feedback="Please provide a postal code"/>
                                <profile-info-card-input-edit v-model="country" :input-value="country" label="Country" invalid-feedback="Please provide a country"/>
                            </div>
                            
                            <div class="d-flex align-items-center justify-content-end mt-3" v-if="isHrAdmin || isHrAdminExFinancials || canChangeEmployee">
                                <div class="edit-save-button-cont">
                                    <button-bento v-if="isEditing" color="plain-transparent" @click="cancelChangesHandler" size="sm">
                                        Cancel
                                    </button-bento>
                                    <button-bento v-if="!isEditing" color="dark" @click="editHandler" size="sm">
                                        <img src="@/assets/v2/icons/edit/edit-pencil-solid-white.svg" class="me-2"/> <span>Edit Profile</span>
                                    </button-bento>
                                    <button-bento v-if="isEditing && areChanges" color="dark" size="sm" type="submit">
                                        <img src="@/assets/v2/icons/edit/save-white.svg" class="me-2 save-white-icon"/> <span>Save Changes</span>
                                    </button-bento>
                                </div>
                            </div>
                        </div>
                        
                        
                        <div v-if="false">
                            <section-header class="mt-5">Additional Details</section-header>
                            <div class="mt-n4 mb-5">
                                <div class="row g-3">
                                    <profile-info-card v-if="!isEditing" title="Title" :value="title"/>
                                    <profile-info-card-input-edit v-else v-model="title" :input-value="title" label="Title" :is-required="false"/>
                                </div>
                            </div>
                        </div>
                    
                    
                    </MDBCollapse>
                </card-general>
            
            </MDBCol>
        </MDBRow>

    </div>
</template>
<script setup>
    import {MDBFileUpload} from "mdb-vue-file-upload"
    import {MDBRow, MDBCol, MDBCollapse} from "mdb-vue-ui-kit"
    import CardGeneral from "@/components/UI/v2/containers-cards-headers/CardGeneral.vue"
    import PageHeader from "@/components/UI/v2/containers-cards-headers/PageHeader.vue"
    import ButtonBento from "@/components/UI/v2/buttons/ButtonBento.vue"
    import {useStore} from "vuex"
    import {ref, computed, watch} from 'vue'
    import SectionHeader from "@/components/UI/v2/containers-cards-headers/SectionHeader.vue"
    import LoadingFullScreen from "@/components/UI/v2/loading/LoadingFullScreen.vue"
    import ProfileInfoCard from "@/components/employee-profile-directory/ui-components/ProfileInfoCard.vue"
    import ProfileInfoCardInputEdit from "@/components/employee-profile-directory/ui-components/ProfileInfoCardInputEdit.vue"
    import ProfileInfoCardTextAreaEdit from "@/components/employee-profile-directory/ui-components/ProfileInfoCardTextAreaEdit.vue"
    import {isValidArray} from "@/hooks/general/type-and-value-checks"
    import BentoAlert from "@/components/UI/v2/alerts/BentoAlert.vue"
    import {isRequiredValidate} from "@/hooks/general/form-validators"
    import userPlaceholder from "@/assets/v2/placeholders/user-placeholder.jpg"
    import {getEmployeeFullName} from "@/hooks/general/employee-info-utilities"
    import {joinPartsIntoOneLiner} from "@/hooks/general/text-formatters"
    import {isProd} from "@/hooks/api/app-environment-origin";
    
    const store = useStore()
    const props = defineProps({
        employeeProp: {
            type: Object,
            required: true
        }
    })

    // permissions
    const isHrAdmin = computed(() => store.getters.isHrAdmin)
    const isHrAdminExFinancials = computed(() => store.getters.isHrAdminExFinancials)
    const canChangeEmployee = computed(() => store.getters.canChangeEmployee)

    const employer = computed(() => store.getters.employer)
    const isBentoDemoCompany = computed(() => employer.value.name === 'Bento Demo' || (employer.value.id === 6 && isProd()) )
    
    const personalProfileCollapse = ref(false)
    const employee = computed(() => props.employeeProp)
    
    const fullName = computed(() => getEmployeeFullName(employee.value))
    
    // Original values
    const originalJobTitle = ref(employee.value?.job_title ?? null) // not editable
    const originalEmail = ref(employee.value?.user.email ?? null) // not editable
    const originalIdNumber = ref(employee.value?.id_number ?? null) // not editable
    const originalPreferredName = ref(employee.value?.preferred_name ?? null)
    const originalCellNumber = ref(employee.value?.cell_number ?? null)
    const originalAddress = ref(employee.value?.address ?? null)
    const originalSelfDescription = ref(employee.value?.self_description ?? null)
    const originalEcName = ref(employee.value?.emergency_contact?.name ?? '')
    const originalEcRelationship = ref(employee.value?.emergency_contact?.relationship ?? '')
    const originalEcCellNumber = ref(employee.value?.emergency_contact?.cell_number ?? '')
    const originalUnitNumber = ref(employee.value?.physical_address?.unit_number ?? null)
    const originalComplex = ref(employee.value?.physical_address?.complex ?? null)
    const originalStreetNumber = ref(employee.value?.physical_address?.street_number ?? null)
    const originalStreet = ref(employee.value?.physical_address?.street ?? null)
    const originalSuburb = ref(employee.value?.physical_address?.suburb ?? null)
    const originalCity = ref(employee.value?.physical_address?.city ?? null)
    const originalPostalCode = ref(employee.value?.physical_address?.postal_code ?? null)
    const originalCountry = ref(employee.value?.physical_address?.country ?? null)
    const originalTitle = ref(employee.value?.title ?? null)
    
    // Current values
    const jobTitle = ref(originalJobTitle.value) // not editable
    const email = ref(originalEmail.value) // not editable
    const idNumber = ref(originalIdNumber.value) // not editable
    const preferredName = ref(originalPreferredName.value)
    const cellNumber = ref(originalCellNumber.value)
    const address = ref(originalAddress.value)
    const selfDescription = ref(originalSelfDescription.value)
    const ecName = ref(originalEcName.value)
    const ecRelationship = ref(originalEcRelationship.value)
    const ecCellNumber = ref(originalEcCellNumber.value)
    const unitNumber = ref(originalUnitNumber.value)
    const complex = ref(originalComplex.value)
    const streetNumber = ref(originalStreetNumber.value)
    const street = ref(originalStreet.value)
    const suburb = ref(originalSuburb.value)
    const city = ref(originalCity.value)
    const postalCode = ref(originalPostalCode.value)
    const country = ref(originalCountry.value)
    const title = ref(originalTitle.value)
    
    // Joined one liner values
    const joinedOneLineEmergencyContact = computed(() => joinPartsIntoOneLiner([ecName.value, ecRelationship.value, ecCellNumber.value]))
    const joinedOneLineAddress = computed(() => joinPartsIntoOneLiner([unitNumber.value, complex.value, streetNumber.value, street.value, suburb.value, city.value, postalCode.value, country.value]))
    
    const areChanges = computed(() => {
        return (
            jobTitle.value !== originalJobTitle.value ||
            email.value !== originalEmail.value ||
            idNumber.value !== originalIdNumber.value ||
            preferredName.value !== originalPreferredName.value ||
            cellNumber.value !== originalCellNumber.value ||
            address.value !== originalAddress.value ||
            selfDescription.value !== originalSelfDescription.value ||
            ecName.value !== originalEcName.value ||
            ecRelationship.value !== originalEcRelationship.value ||
            ecCellNumber.value !== originalEcCellNumber.value ||
            unitNumber.value !== originalUnitNumber.value ||
            complex.value !== originalComplex.value ||
            streetNumber.value !== originalStreetNumber.value ||
            street.value !== originalStreet.value ||
            suburb.value !== originalSuburb.value ||
            city.value !== originalCity.value ||
            postalCode.value !== originalPostalCode.value ||
            country.value !== originalCountry.value ||
            title.value !== originalTitle.value
        )
    })
    
    const isEditing = ref(false)
    const editHandler = () => isEditing.value = true
    
    const resetValuesToCurrent = () => {
        jobTitle.value = originalJobTitle.value
        email.value = originalEmail.value
        idNumber.value = originalIdNumber.value
        preferredName.value = originalPreferredName.value
        cellNumber.value = originalCellNumber.value
        address.value = originalAddress.value
        selfDescription.value = originalSelfDescription.value
        ecName.value = originalEcName.value
        ecRelationship.value = originalEcRelationship.value
        ecCellNumber.value = originalEcCellNumber.value
        unitNumber.value = originalUnitNumber.value
        complex.value = originalComplex.value
        streetNumber.value = originalStreetNumber.value
        street.value = originalStreet.value
        suburb.value = originalSuburb.value
        city.value = originalCity.value
        postalCode.value = originalPostalCode.value
        country.value = originalCountry.value
        title.value = originalTitle.value
    }
    
    const cancelChangesHandler = () => {
        isEditing.value = false
        incompleteAlert.value = false
        resetValuesToCurrent()
    }
    
    
    const requiredFieldsComplete = computed(() => {
        return isRequiredValidate(cellNumber.value) &&
            isRequiredValidate(ecName.value) &&
            isRequiredValidate(ecRelationship.value) &&
            isRequiredValidate(ecCellNumber.value) &&
            isRequiredValidate(streetNumber.value) &&
            isRequiredValidate(street.value) &&
            isRequiredValidate(suburb.value) &&
            isRequiredValidate(city.value) &&
            isRequiredValidate(postalCode.value) &&
            isRequiredValidate(country.value);
    })
    
    const incompleteAlert = ref(false)
    const checkForm = (event) => {
        incompleteAlert.value = false
        event.target.classList.add('was-validated')
        if (requiredFieldsComplete.value) {
            updatePersonalInfo()
        } else {
            incompleteAlert.value = true
        }
    }
    
    // profile pic
    const showFileUploader = ref(false)
    const isProfilePicChanged = ref(false)
    
    
    const profilePic = ref(employee.value?.profile_picture || userPlaceholder)
    if (profilePic.value && profilePic.value.length) {
        profilePic.value = profilePic.value.replace('http://', 'https://')
    }
    
    const uploadedProfilePic = ref(null)
    const fileUploadHandler = (fileList) => {
        isProfilePicChanged.value = true
        uploadedProfilePic.value = null
        if (isValidArray(fileList)) {
            uploadedProfilePic.value = fileList[0]
        }
    }
    
    const fileUploadRef = ref(null) // so I can acesss fileUploader methods -> https://mdbootstrap.com/docs/vue/plugins/file-upload/#docsTabsAPI
    const cancelAndClearFileUploadHandler = () => {
        fileUploadRef.value?.clear()
        showFileUploader.value = false
        isProfilePicChanged.value = false
    }
    
    
    // Watch for changes in employeeProp (changing employee from dropdown)
    watch(
        () => props.employeeProp,
        (newSelectedEmployee) => {
            isEditing.value = false
            showFileUploader.value = false

            // Update ref values on employee change
            email.value = newSelectedEmployee?.user?.email
            idNumber.value = newSelectedEmployee?.id_number
            preferredName.value = newSelectedEmployee?.preferred_name
            jobTitle.value = newSelectedEmployee?.job_title
            cellNumber.value = newSelectedEmployee?.cell_number
            address.value = newSelectedEmployee?.address
            selfDescription.value = newSelectedEmployee?.self_description
            profilePic.value = newSelectedEmployee?.profile_picture || userPlaceholder
            
            // Update emergency contact details
            const newEmergencyContact = newSelectedEmployee?.emergency_contact || {}
            ecName.value = newEmergencyContact?.name || ''
            ecRelationship.value = newEmergencyContact?.relationship || ''
            ecCellNumber.value = newEmergencyContact?.cell_number || ''
            
            
            // Update physical address details
            const newPhysicalAddress = newSelectedEmployee?.physical_address || {}
            unitNumber.value = newPhysicalAddress.unit_number || null
            complex.value = newPhysicalAddress.complex || null
            streetNumber.value = newPhysicalAddress.street_number || null
            street.value = newPhysicalAddress.street || null
            suburb.value = newPhysicalAddress.suburb || null
            city.value = newPhysicalAddress.city || null
            postalCode.value = newPhysicalAddress.postal_code || null
            country.value = newPhysicalAddress.country || null
        },
        {deep: true} // Deep watch to observe nested properties
    )
    
    
    // the api call to update personal info
    const isUpdatingPersonalInfo = ref(false)
    const updatePersonalInfo = async () => {
        isUpdatingPersonalInfo.value = true
        
        const payload = {
            "employee_id": employee.value?.id,
            "preferred_name": preferredName.value,
            "cell_number": cellNumber.value,
            "address": address.value,
            "self_description": selfDescription.value,
            "profile_picture": uploadedProfilePic.value,
            "physical_address": {
                "unit_number": unitNumber.value,
                "complex": complex.value,
                "street_number": streetNumber.value,
                "street": street.value,
                "suburb": suburb.value,
                "city": city.value,
                "postal_code": postalCode.value,
                "country": country.value
            },
            "emergency_contact": {
                "name": ecName.value,
                "relationship": ecRelationship.value,
                "cell_number": ecCellNumber.value
            },
        }
        
        try {
            await store.dispatch('updateEmployeeInfo', payload) // PATCH request
            const updatedEmployee = await store.dispatch('loadEmployee', {forceRefresh: true, employee_id: employee.value?.id})
            if (updatedEmployee && updatedEmployee.profile_picture && updatedEmployee.profile_picture.length) {
                profilePic.value = updatedEmployee.profile_picture
                profilePic.value = profilePic.value.replace('http://', 'https://')
            }
        } catch (error) {
            store.commit('setShowApiErrorModal', true)
            store.commit('setApiErrorModalTitle', 'Error updating your profile info')
            store.commit('setApiErrorMessage', error.message)
            isUpdatingPersonalInfo.value = false
            isProfilePicChanged.value = false
            return
        }
        
        showFileUploader.value = false
        isProfilePicChanged.value = false
        isUpdatingPersonalInfo.value = false
        isEditing.value = false
    }
</script>

<style lang="scss" scoped>
    @import "@/styles/global-scss/variables-and-mixins.scss";
    
    // title and buttons
    .page-title-and-buttons-cont {
        flex-wrap: wrap;
        @media (min-width: 544px) {
            flex-wrap: nowrap;
        }
        border-radius: $border-radius;
        .top-border-only {
            border-radius: $border-radius $border-radius 0 0 !important;
        }
        &:hover {
            @include green;
        }
    }
    .page-title {
        flex: 0 0 100%;
        @media (min-width: 544px) {
            flex: 1;
        }
    }
    .edit-save-button-cont {
        flex: 0 0 100%;
        margin-top: 8px;
        @media (min-width: 544px) {
            margin-top: 0;
            flex: 1;
            text-align: right;
        }
        .btn {
            margin-top: 4px;
        }
    }
    .save-white-icon {
        width: 17px;
        position: relative;
        top: -1px;
    }
    
    // image, name, self description
    .top-content-row {
        flex-wrap: wrap;
        @media (min-width: 460px) {
            flex-wrap: nowrap;
        }
    }
    
    .profile-pic-image-col {
        flex: 0 0 100%;
        max-width: 244px;
        order: 2;
        margin-top: 42px;
        @media (min-width: 460px) {
            flex: 1;
            order: 1;
            margin-top: 0;
        }
    }
    .profile-pic-uploader-cont {
        height: 200px;
        box-shadow: none;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
    }
    .profile-img {
        border-radius: $border-radius;
    }
    :deep(.file-upload-remove-file-btn), :deep(.file-upload-file-name) {
        display: none !important;
    }
    
    
    .name-and-title-col {
        flex: 0 0 100%;
        order: 1;
        @media (min-width: 460px) {
            flex: 1;
            order: 2;
        }
    }
    .name-and-title-card {
        @media (min-width: 460px) {
            min-height: 242px;
        }
    }
    :deep(.file-upload-wrapper) {
        background: rgba(white, .8);
        
        .file-upload-message {
            padding: 10px;
        }
        .fa-cloud-upload-alt:before, .file-upload-default-message {
            color: $gradient-green-text-dark;
        }
        .file-upload-default-message {
            padding: 0 10px;
        }
        .file-upload-preview-img {
            border-radius: 12px;
        }
        .file-upload-preview-details-inner {
            padding-left: 10px;
            padding-right: 10px;
            .file-upload-file-name {
                margin-bottom: 6px;
            }
            .file-upload-preview-message {
                margin-bottom: 2px;
            }
        }
    }
    
    .edit-image-icon {
        border-radius: 3px;
        padding: 4px;
        width: 34px;
        top: 20px;
        left: 20px;
        background: rgba(black, .05);
        z-index: 999;
        text-align: center;
        img {
            width: 100%;
            &.cancel-img {
                max-width: 20px;
            }
        }
    }
    
    .job-title {
        color: $gradient-green-text-dark;
        font-weight: 600;
        font-size: 15px;
        margin-top: 2px;
        white-space: nowrap;
        @include media-breakpoint-up(md) {
            font-size: 16px;
        }
    }
    
    .self-description {
        color: $gradient-green-text-light;
    }
    
    .invalid-feedback-custom {
        font-size: 0.875rem;
        color: $danger-500;
        line-height: 1.3;
    }
</style>