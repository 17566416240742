<template>
    <div class="block-container">

        <header
            :class="{ expanded: blockExpanded }"
            @click="onToggleExpandOpposite"
            class="d-flex rounded justify-content-start position-relative align-items-center p-4"
        >
            <div class="title-and-sub-title d-flex">
                <div class="text-gradient-blue-text-dark font-weight-600 m-0 font-size-16">
                    {{ reimbursementType.name }}
                </div>
                <div class="sub-title text-gradient-blue-text-light font-size-14 font-weight-600 ms-2 d-none d-sm-block d-md-none d-lg-block">
                    &bull;
                    <span v-if="reimbursementType.budgeted"><span>{{ bentoCurrencyFormat(remainingBalance) }}</span> remaining</span><span v-else>No set budget</span>
                </div>
            </div>
            <three-dots-toggle/>
        </header>

        <div v-if="false">
            <div>ReimbursementType:</div>
            <pre>{{ reimbursementType }}</pre>

            <div>remainingBalance</div>
            <pre>{{ remainingBalance }}</pre>
        </div>

        <div v-if="blockExpanded" class="block-expanded-content">
            <div class="mt-5">

                <div v-if="reimbursementType.budgeted">
                    <bento-alert color="info" class="mt-n4 mb-4" static dismiss v-model="budgetExplanation" icon-type="info-info">
                        <div v-if="reimbursementType.period == 'lifetime'">
                            <span class="font-weight-600">{{ bentoCurrencyFormat(props.reimbursementType.amount) }} lifetime budget</span>. This is a once off allowance which can be spent over a number of years.
                        </div>
                        <div v-else-if="reimbursementType.period == 'year'">
                            <span class="font-weight-600">{{ bentoCurrencyFormat(props.reimbursementType.amount) }} / year</span>. This is an annual allowance which resets at the end of the calendar year.
                        </div>
                        <div v-else-if="reimbursementType.period == 'month'">
                            <span class="font-weight-600">{{ bentoCurrencyFormat(props.reimbursementType.amount) }} / month</span>. This is a monthly allowance which resets at the end of the monthly reimbursement period.
                        </div>
                    </bento-alert>
                </div>

                <div v-if="reimbursementType?.type?.extra_content" class="mt-n3 mb-4">
                    <bento-alert color="warning" class="mt-4" static dismiss v-model="allowanceExtraNote" icon-type="info-warning">
                        <span v-html="reimbursementType.type.extra_content"></span>
                    </bento-alert>
                </div>

                <div v-if="reimbursementType.budgeted">
                    <balance-bar :reimbursement-allowance="reimbursementType"/>
                </div>
            </div>



            <form @submit.prevent="submitExpenseClaimHandler" class="mt-2 pt-3">
                <div class="mb-2 row">
                    <div class="col-12 col-sm-6">
                        <div class="mb-2">
                            <div><label for="amount">Claim amount</label></div>
                            <CurrencyInput
                                :key="claimAmountResetKey"
                                v-model.lazy="claimAmount"
                                :options="{
                                    currency: 'ZAR',
                                    locale: 'en-ZA',
                                    currencyDisplay: 'symbol',
                                    hideCurrencySymbolOnFocus: false,
                                    hideGroupingSeparatorOnFocus: false,
                                    valueRange: { min: 0, max: 100000 },
                                    precision: 2
                                }"
                            />
                            <div class="font-size-11 text-gray-700">Max R100,000</div>
                        </div>
                        <div class="mb-2 py-2">
                            <div><label>Date of invoice (YYYY-MM-DD)</label></div>
                            <MDBDatepicker
                                v-model="invoiceDate"
                                format="YYYY-MM-DD"
                                inline
                                label="" size="lg"
                            />
                            <div class="font-size-11 text-gray-700">Click calendar for datepicker</div>
                        </div>

                        <div class="mb-3">
                            <div><label for="amount">Include a note</label></div>
                            <MDBTextarea
                                label="" rows="4"
                                v-model="notes"
                            />
                        </div>
                    </div>

                    <div id="invoice-uploader" class="col-12 col-sm-6 pt-4">
                        <MDBFileUpload
                            class="mt-1"
                            @change="invoiceUploadHandler"
                            ref="fileInvoiceRef"
                            defaultMsg="Upload invoice or receipt"
                            :maxFileSize="3"
                            :height="280"
                            multiple
                            :maxFilesQuantity="5"
                            accept=".bmp,.csv,.doc,.docx,.eml,.gif,.jpeg,.jpg,.key,.msg,.numbers,.odf,.ods,.odt,.pages,.pdf,.png,.ppt,.pptx,.rar,.rtf,.rtf,.txt,.tif,.tiff,.xls,.xlsx,.zip,.7z"
                        />
                        <div class="font-size-11 text-gray-700 text-end">Max 5 files</div>
                    </div>

                </div>

                <div v-if="!areFormFieldsValid">
                    <bento-alert color="secondary" class="mb-3" static dismiss v-model="completeAllFieldsNotice">
                        Please complete all fields and upload an invoice / receipt.
                    </bento-alert>
                </div>

                <div class="mt-4" v-if="apiResponseError">
                    <bento-alert color="secondary" class="mb-3" static dismiss v-model="apiResponseErrorNotice">
                        <span v-html="apiResponseError"></span>
                    </bento-alert>
                </div>

                <div class="mt-4" v-if="showClaimConfirmation && !apiResponseError">
                    <bento-alert color="info" icon-type="success-tick" class="mb-3" static dismiss v-model="expenseClaimSubmittedNotice">
                        Expense claim submitted.
                    </bento-alert>
                </div>

                <div class="loading-changes-cont" v-if="isSubmittingClaim">
                    <loading-small v-model="isSubmittingClaim" parentSelector=".loading-changes-cont" loadingText="Submitting claim ..." class="text-gradient-blue-text-dark"/>
                </div>

                <div class="d-flex justify-content-between align-items-center mt-2">
                    <div @click="resetExpenseClaimHandler" class="font-weight-600 font-size-16 cursor-pointer">
                        Clear
                    </div>
                    <button-bento @click.prevent="submitExpenseClaimHandler" class="me-2" size="sm" button-color="dark">
                        Submit Claim
                    </button-bento>
                </div>
            </form>
        </div>
    </div>
</template>

<script setup>
import BalanceBar from "@/components/benefits-leave-reimbursements/reimbursements/wizard/BalanceBar"
import {ref, computed, onMounted} from 'vue'
import ButtonBento from "@/components/UI/v2/buttons/ButtonBento"
import LoadingSmall from "@/components/UI/v2/loading/LoadingSmall"
import {MDBFileUpload} from "mdb-vue-file-upload"
import {MDBTextarea, MDBDatepicker} from 'mdb-vue-ui-kit'
import {bentoCurrencyFormat} from "@/hooks/general/currency-number-formatters"
import BentoAlert from "@/components/UI/v2/alerts/BentoAlert.vue"
import ThreeDotsToggle from "@/components/UI/v2/misc/ThreeDotsToggle.vue"
import {useStore} from "vuex"
import {isValidArray} from "@/hooks/general/type-and-value-checks"
import CurrencyInput from "@/components/UI/v2/forms/CurrencyInput.vue"

const store = useStore()

const allowanceExtraNote = ref(false)
const budgetExplanation = ref(false)
const completeAllFieldsNotice = ref(false)
const apiResponseErrorNotice = ref(false)
const expenseClaimSubmittedNotice = ref(false)
completeAllFieldsNotice.value = true
apiResponseErrorNotice.value = true
expenseClaimSubmittedNotice.value = true

const props = defineProps({
    blockExpandedProp: {
        type: Boolean,
        required: false,
        default: false
    },
    isAdHoc: {
        type: Boolean,
        required: false,
        default: false
    },
    reimbursementType: {
        type: Object,
        required: false,
    },
})

const blockExpanded = ref(props.blockExpandedProp)
const isSubmittingClaim = ref(false)
const apiResponseError = ref(false)
const claimAmount = ref(0)
const claimAmountResetKey = ref(0)
const currentDate = new Date().toISOString().split('T')[0]
const invoiceDate = ref('')
onMounted(() => {
    invoiceDate.value = currentDate
})

const notes = ref(null)
const areFormFieldsValid = ref(true)
const showClaimConfirmation = ref(false)


const remainingBalance = computed(() => Number(props.reimbursementType.amount) - Number(props.reimbursementType.claimed_total_for_current_period))

// file uploader
const fileInvoiceRef = ref(null) // so I can do methods on it (e.g. clear()) -> https://mdbootstrap.com/docs/vue/plugins/file-upload/#docsTabsAPI
const uploadedInvoices = ref(null)

// ### multifile
const invoiceUploadHandler = (fileList) => {
    showClaimConfirmation.value = false
    uploadedInvoices.value = null
    if (isValidArray(fileList)) {
        uploadedInvoices.value = fileList
    }
}

const submitExpenseClaimHandler = async () => {

    areFormFieldsValid.value = true
    apiResponseError.value = false

    // form validation
    if (!claimAmount.value || !notes.value || !isValidArray(uploadedInvoices.value)) {
        areFormFieldsValid.value = false
        return
    }


    isSubmittingClaim.value = true
    showClaimConfirmation.value = false
    apiResponseError.value = false

    const reimbursementAllowanceId = props.reimbursementType.id
    const expenseClaimObject = {
        allowance: reimbursementAllowanceId,
        date: currentDate,
        invoice_date: invoiceDate.value,
        notes: notes.value,
        amount: claimAmount.value,
        // file: uploadedInvoices.value, // use to use single file upload
        files: uploadedInvoices.value, // use for the multifile upload - Can only use 1 of th 2
        state: 'submitted'
    }

    try {
        await store.dispatch('postReimbursementClaimApi', expenseClaimObject)
    } catch (error) {
        apiResponseError.value = `${error.message}<br>If you have a submitted claim that you would like to cancel, please ask your approver to reject the claim.`
        isSubmittingClaim.value = false
        return
    }

    resetExpenseClaimHandler()

    showClaimConfirmation.value = true
    isSubmittingClaim.value = false
}

const resetExpenseClaimHandler = () => {
    claimAmount.value = 0
    claimAmountResetKey.value++
    notes.value = null
    invoiceDate.value = currentDate
    fileInvoiceRef.value?.clear()
    showClaimConfirmation.value = false
}

const onToggleExpandOpposite = () => blockExpanded.value = !blockExpanded.value
</script>

<style lang="scss" scoped>
@import "@/styles/global-scss/variables-and-mixins.scss";

.header-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @media (min-width: 380px) {
        flex-wrap: nowrap;
    }
}

.payroll-label-container {
    flex: 0 0 100%;
    @media (min-width: 380px) {
        flex: 1 auto;
        text-align: right;
    }
}

.payroll-month {
    line-height: 1;
    padding-right: 0;
    @media (max-width: 379px) {
        font-size: 16px;
        margin-top: 16px;
    }
}

/*uploader*/

:deep(#invoice-uploader) {
    .file-upload {
        border-radius: 6px;
    }

    .file-upload-mask {
        opacity: .07;
        border-radius: 6px;
    }
    .file-upload:hover {
        .file-upload-mask {
            opacity: 0.08;
        }
    }
    .file-upload-remove-file-btn {
        padding: 4px 8px;
        font-weight: 600;
    }

    .file-upload-message {
        font-weight: 500;
        font-size: 12px;
        color: $gray-main;
        i.fas {
            color: $gradient-blue-text-dark;
        }
    }
}

.is-submitting-claim {
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
}

/*Header of block styles and basic structure*/
.block-container {
    margin-bottom: 30px;
    background: $gradient-blue-2;
    border-radius: $border_radius;
}

header {
    cursor: pointer;
    background: $gradient-blue;
    transition: all .15s ease-in-out;
    &:hover {
        background: $gradient-blue-darken;
    }
    &.expanded {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
}

.title-and-sub-title {
    flex: 1 auto;
}

.sub-title {
    line-height: 25px;
}

.block-expanded-content {
    padding: 0 15px 25px;
    line-height: 1.8;
    @include media-breakpoint-up(sm) {
        padding: 0 20px 25px;
    }
    @include media-breakpoint-up(md) {
        padding: 0 25px 25px;
    }
}

// HTML Input amount styling
label {
    font-size: 12px;
    position: relative;
    left: 1px;
    color: $gradient-blue-text-dark;
    font-weight: 500;
}

input {
    width: 100%;
    padding: 5px 8px;
    color: $gradient-blue-text-dark;
    font-size: 16px;
    border: 1px solid #bdbdbd;
    border-radius: 5px;
    outline: none !important;
    margin-bottom: 2px;
    &:focus-visible {
        margin-bottom: 0;
        outline: none !important;
        border: 2px solid $gradient-blue-text-dark;
    }
}

// MDB Styling
:deep(.form-outline) {
    label.form-label {
        font-size: 13px;
    }

    textarea {
        padding-top: 7px;
        font-size: 14px;
    }

    .form-control {
        color: $gradient-blue-text-dark;
        background-color: white;

        &:focus ~ .form-label {
            color: $gradient-blue-text-dark;
        }
        &:focus ~ .form-notch .form-notch-middle {
            border-color: $gradient-blue-text-dark;
            box-shadow: 0 1px 0 0 $gradient-blue-text-dark;
            border-top: 1px solid transparent;
        }
        &:focus ~ .form-notch .form-notch-leading {
            border-color: $gradient-blue-text-dark;
            box-shadow: -1px 0 0 0 $gradient-blue-text-dark, 0 1px 0 0 $gradient-blue-text-dark, 0 -1px 0 0 $gradient-blue-text-dark;
        }
        &:focus ~ .form-notch .form-notch-trailing {
            border-color: $gradient-blue-text-dark;
            box-shadow: 1px 0 0 0 $gradient-blue-text-dark, 0 -1px 0 0 $gradient-blue-text-dark, 0 1px 0 0 $gradient-blue-text-dark;
        }
    }

}
</style>