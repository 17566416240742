<template>
    <div>
        <div v-if="!isEnabledReimbursements">
            <width-container>
                <bento-alert static color="danger">Reimbursements is not enabled for your company.</bento-alert>
            </width-container>
        </div>

        <div v-else-if="(!canDownloadReimbursementReports && !isReimbursementApprover && !isHrAdmin)">
            <width-container>
                <bento-alert static color="danger">You do not have permissions to access this page.</bento-alert>
            </width-container>
        </div>
        
        <div v-else>
            <div v-if="isLoading">
                <loading-full-screen v-model="isLoading" loadingText="Load reimbursement claims ..."/>
            </div>
            
            <width-container v-else>
                <div class="pb-5">
                    <div>
                        <h1 class="mb-4 pb-2">
                            <span class="d-inline-block me-4">My Team</span> <span class="d-inline-block me-3 title-chevron"><img src="@/assets/v2/icons/chevron.svg" alt="Chevron right"/></span> <span class="d-inline-block font-weight-400">Reimbursements</span>
                        </h1>
                        
                        <div v-if="isReimbursementApprover">
                            <reimbursement-management-claims is="reimbursement-claims" />
                        </div>

                        <download-reimbursements-report id="download-reimbursements-report" v-if="(canDownloadReimbursementReports || showTeamAdminInterface) && isEnabledReimbursements"/>
                    </div>
                </div>
            </width-container>
        </div>
    </div>
</template>

<script setup>
    import WidthContainer from "@/components/UI/v2/containers-cards-headers/WidthContainer"
    import {ref, computed, onMounted, watch, nextTick} from "vue"
    import {useRoute} from 'vue-router'
    import {useStore} from "vuex"
    import BentoAlert from "@/components/UI/v2/alerts/BentoAlert"
    import LoadingFullScreen from "@/components/UI/v2/loading/LoadingFullScreen"
    import DownloadReimbursementsReport
        from "@/components/manager/reimbursements/reimbursement-management/DownloadReimbursementsReport.vue";
    import ReimbursementManagementClaims
        from "@/components/manager/reimbursements/reimbursement-management/ReimbursementManagementClaims.vue";
    
    const store = useStore()
    
    const isLoadingEmployee = ref(false)
    const isLoading = computed(() => isLoadingEmployee.value)
    
    const isReimbursementApprover = computed(() => store.getters.isReimbursementApprover)
    const canDownloadReimbursementReports = computed(() => store.getters.canDownloadReimbursementReports)
    const showTeamAdminInterface = computed(() => store.getters.showTeamAdminInterface)
    const isEnabledReimbursements = store.getters.isEnabledReimbursements
    
    const route = useRoute()
    const isInitialLoad = ref(true)

    const scrollToSection = hash => {
        nextTick(() => {
            requestAnimationFrame(() => {
                const elementId = hash.replace('#', '')
                const element = document.getElementById(elementId)
                if (element && element.offsetParent !== null) {
                    const yOffset = -100
                    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset
                    window.scrollTo({top: y, behavior: 'smooth'})
                }
            })
        })
    }

    onMounted(() => {
        if (route.hash) {
            scrollToSection(route.hash);
        }
        isInitialLoad.value = false;
    });

    watch(() => route.hash, (newHash) => {
        if (newHash && !isInitialLoad.value) {
            scrollToSection(newHash);
        }
    });
    
    onMounted(() => {
        if (route.hash) {
            scrollToSection(route.hash)
        }
        isInitialLoad.value = false
    })
    
    watch(() => route.hash, (newHash) => {
        if (newHash && !isInitialLoad.value) {
            scrollToSection(newHash)
        }
    })

</script>

<style lang="scss" scoped>
    .title-chevron {
        position: relative;
        top: -2px;
    }
</style>