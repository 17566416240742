// manager leave mutations

export default {
    setLeaveApproveeList: (state, payload) => state.leaveApproveeList = payload,
    setLastLeaveApproveeListFetch: (state, payload) => state.lastLeaveApproveeListFetch = payload,

    updateLeaveApproveeList: (state, payload) => {
        const approveeIndex = state.leaveApproveeList?.findIndex(approvee => approvee.id == payload.employeeID) ?? -1
        if (approveeIndex === -1) return

        const leaveRequestIndex = state.leaveApproveeList[approveeIndex]?.leave_requests?.findIndex(request => request.id == payload.requestID) ?? -1
        if (leaveRequestIndex === -1) return

        if (state.leaveApproveeList[approveeIndex] && state.leaveApproveeList[approveeIndex].leave_requests) {
            state.leaveApproveeList[approveeIndex].leave_requests[leaveRequestIndex].state = payload.updatedState
        }
    }
}