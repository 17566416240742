export const toTitleCase = str => {
    if (!str) return null
    return str.replace(/\w\S*/g, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase())
}

export const joinPartsIntoOneLiner = parts => {
    let oneLiner = ''
    parts.forEach(part => {
        if (part && part.trim() !== 'null') {
            oneLiner += part.trim() + ', '
        }
    })
    oneLiner = oneLiner.trim()
    return oneLiner.endsWith(',') ? oneLiner.slice(0, -1) : oneLiner
}

export const convertToKebabCase = string => string.replace(/\W+/g, " ")
    .split(/ |\B(?=[A-Z])/)
    .map(word => word.toLowerCase())
    .join('-')

export const convertSnakeToCapitalCase = input => {
    let inputWithSpaces = input.replace(/_/g, ' ')
    const words = inputWithSpaces.split(" ")
    for (let i = 0; i < words.length; i++) {
        words[i] = words[i][0].toUpperCase() + words[i].substr(1)
    }
    return words.join(" ")
}

export const convertToSnakeCase = string => string.replace(/\W+/g, " ")
    .split(/ |\B(?=[A-Z])/)
    .map(word => word.toLowerCase())
    .join('_')

export const getFirstWordTitleCase = string => toTitleCase(string.split(' ')[0])

export const getFirstWord = string => {
    if (!string) return null
    return string.split(' ')[0]
}

export const textTruncater = (str = '', limit = 25) => str.length >= limit ? str.substring(0, limit) + ' ...' : str

export const textTruncaterWords = (str, maxWords) => {
    if ( str == null || str.trim() === '') return ''
    const words = str.split(/\s+/)
    return words.length > maxWords ? words.slice(0, maxWords).join(' ') + '...' : str
}

export const convertSnakeToSentenceCase = input => {
    let inputWithSpaces = input.replace(/_/g, ' ')
    return inputWithSpaces.charAt(0).toUpperCase() + inputWithSpaces.slice(1).toLowerCase()
}