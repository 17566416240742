// manager leave actions

import theApiCall from '@/hooks/api/the-api-call'
import {apiResponseHandler} from "@/hooks/api/api-helper-function"

export default {

    async loadLeaveApproveeList(context, payload) {
        if (!payload.forceRefresh && !context.getters.shouldUpdateLeaveApproveeList) { return }

        const userApiId = payload.otherUserId ? payload.otherUserId : (localStorage.userId ? localStorage.userId : context.getters.userApiId)
        const response = await theApiCall(`employees/${userApiId}/leave_approvees/`)
        const responseData = await apiResponseHandler(response, 'Could not load your leave approvee list.', 'Leave approvees resource not found. [404]')

        if (!payload.otherUserId) {
            context.commit('setLeaveApproveeList', responseData)
            context.commit('setLastLeaveApproveeListFetch', {timestamp: new Date().getTime()})
        } else {
            return responseData
        }
    },

    async getFutureLeaveBalanceForApprovee(context, payload) {
        const response = await theApiCall(`employees/${payload.approveeID}/future_leave_balances/?date=${payload.date}`)
        const responseData = await apiResponseHandler(response, 'Could not fetch future leave balance.', 'Future leave balance calc not found. [404]')
        return responseData
    },

}