import {stagingOrDevelopmentOrAppApiUrl} from "@/hooks/api/app-environment-origin"
import store from "@/store"

export default async function apiCall(urlEndPoint, method = 'GET', body = null, contentType = null) {
    let userId = localStorage.userId ? localStorage.userId : store.getters.userApiId
    let actualUserId = localStorage.actualUserId

    if (urlEndPoint === 'employers/') { userId = actualUserId } // fetch employer list as admin / original userId -> so can still list all employers on /bento-admin

    let headers = new Headers()
    let authToken = localStorage.getItem('authToken')

    headers.set('Authorization', 'Token ' + authToken)

    if (contentType != null && !(body instanceof FormData)) { headers.set('Content-Type', contentType) }
    if (userId != actualUserId) { headers.set('bento-view-as-employee', userId) }

    const response = await fetch(stagingOrDevelopmentOrAppApiUrl(urlEndPoint), { method, headers, body })
    return response
}