<template>
    <div>
        <global-modal-api-error/>
        <loading-full-screen v-if="isLoading" loadingText="Loading app ..."/>
        <the-header/>
        <the-side-nav v-if="!isLoginPage && !isAcceptTermsPage && isAuthenticated"/>
        <the-modals/>

        <div v-if="!isLoading">
            <main id="main-app-wrapper" :class="{'home-page-main': isHomePage, 'login-page': isLoginPage, 'accept-terms-page': isAcceptTermsPage}">
                <router-view name="default" v-slot="slotProps">
                    <transition name="route" mode="out-in">
                        <component :is="slotProps.Component"/>
                    </transition>
                </router-view>
            </main>
        </div>
    </div>
</template>

<script setup>
import TheHeader from "./components/layouts/header/TheHeader"
import LoadingFullScreen from "@/components/UI/v2/loading/LoadingFullScreen"
import TheSideNav from "@/components/layouts/side-nav/TheSideNav.vue"
import {loadAllApiData, loadDataInBackground} from "@/hooks/api/api-dispatch-calls"
import {computed, ref} from "vue"
import {useStore} from "vuex"
import {useRoute} from "vue-router"
import GlobalModalApiError from "@/components/UI/v2/modals/GlobalModalApiError.vue"
import TheModals from "@/components/layouts/header/TheModals.vue"
import {useSendHeartBeat} from "@/hooks/use-component/app/send-heartbeat"

const store = useStore()
const route = useRoute()

const isAuthenticated = computed(() => store.getters.isAuthenticated)
const isLoginPage = computed(() => route.name === 'login')
const isHomePage = computed(() => route.name === 'home')
const isAcceptTermsPage = computed(() => route.name === 'accept-terms')


// try login if local storage is set with email & password & userId -> then set the user in the store
// if localStorage is not set with email and password -> then log user out : this happens in global navigation guard
store.dispatch('tryLogin')

// try auto accept terms if local storage is set with truthy values
store.dispatch('tryAutoAcceptTerms')

// try to autoload userId from localStorage for testing purposes when there is a page refresh
// this is for the bento admin functionality
store.dispatch('tryAutoLoadTestingUserId')

const isLoading = ref(false)
const onLoad = async () => {
    isLoading.value = true
    await loadAllApiData(false)
    isLoading.value = false
    await loadDataInBackground()
}


// need to check auth always, because this loads on login page as well
isAuthenticated.value && onLoad()

// refreshes auth token every 30 minutes
useSendHeartBeat()
</script>

<style lang="scss">
// README
// scss files imported here are compiled and minified. These styles are unscoped and applied everywhere.
// Variables imported here are minified so aren't accessible as variables

// ### Fonts
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Inter:wght@200;300;400;500;600;700&display=swap');

// ### MDB Overrides
// This overrides variables in @/styles/mdb-scss/standard/free/_variables.scss && @/styles/mdb-scss/standard/bootstrap/_variables.scss
// Some of the overrides are also done in variables-and-mixins.scss
@import "@/styles/mdb-overrides/mdb-overrides.scss";

// ### Import individually into the components that you need the variables to be accessible
// Variables declared here also override variables in index.pro.scss imported below
// Remember !default is a Sass flag that indicates conditional assignment to a variable — it assigns a value only if the variable was previously undefined or null
@import "@/styles/global-scss/variables-and-mixins.scss";

// ### Main MDB scss file imported here to be compiled. All partials imported into this
@import "@/styles/mdb-scss/index.pro.scss";

// ### Bento Utilities (Separate from MDB)
@import "@/styles/app-scss/bento-utilities.scss";

// Set browser default base font size
// Bootstrap takes this value and uses it via rems to set values for all kinds of things (e.g. p margin bottom)
// $font-size-base must be set in rem, hence why we set the pixel base here
html, body {
    font-size: 14px;
    background-color: $gray-50;
    color: black;
}

#main-app-wrapper {
    padding-top: 35px;
    min-height: calc(100vh - 68px - 117px - 50px); // 100vh - header menu - footer - footer-margin

    // to make sidebar in 'push' state on initial load, excl. login page
    &:not(.login-page), &:not(.accept-terms-page) {
        margin-right: 0;
        padding-left: 260px;
    }
    &.login-page, &.accept-terms-page {
        transition: none !important;
        margin: auto !important;
        padding-left: 0 !important;
    }
    @media (max-width: 1199px) {
        padding-left: 0 !important;
    }

    @include media-breakpoint-up(sm) {
        min-height: calc(100vh - 68px - 96px - 50px); // 100vh - header menu - footer (footer is different size at this level) - footer margin
    }
}

a {
    color: inherit;
    text-decoration: none;
    &:hover {
        color: inherit;
    }
    &.anchor {
        display: block;
        position: relative;
        top: -30px;
        visibility: hidden;
    }
}
.hover-underline:hover {
    text-decoration: underline !important;
}
.hover-no-underline:hover {
    text-decoration: none !important;
}

.cursor-pointer {
    cursor: pointer !important;
}
.cursor-default {
    cursor: default !important;
}

img {
    max-width: 100%;
}

.separator {
    height: 1px;
    background-color: rgba($gradient-green-text-dark, .2);
}

// MDB components
.tooltip {
    z-index: 10000 !important;
}

.tooltip-inner {
    font-size: 11px;
    color: $gradient-green-2;
    padding: 5px 7px;
    background-color: $gradient-green-text-dark;
    border-radius: 4px;
    font-weight: 600;
}

.alert-dismissible .btn-close {
    padding: 14px 13px;
}

// Some of these classes may be applying to HTML output from the /admin/ E.g. content card content
.span-no-wrap {
    white-space: nowrap;
}

.link-in-card {
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
}

.bold-and-black {
    color: black;
    font-weight: 600;
    white-space: nowrap;
}

.extras-to-hide {
    margin-top: 45px;
}

.video-container {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
}

.video-container iframe,
.video-container object,
.video-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.card-text-sub-div {
    line-height: 1.4;
    font-size: 15px;
    p {
        margin-top: 0;
        line-height: 1.4;
    }
    a {
        font-weight: 500;
        &:hover {
            text-decoration: underline;
        }
    }
}

.retirement-advisor-img {
    display: inline-block;
    width: 152px;
    border-radius: 50%;
}

// Modals
.modal {
    z-index: 99999999;
}

// MDBSelect
.form-check-input {
    width: 15px;
    height: 15px;
}
.form-check-input[type=checkbox]:checked:after {
    margin-left: 4px;
    border-width: 2px;
}

.expanded-content {
    .card-text-sub-div p:last-child {
        margin-bottom: 0;
    }
}

.loading-changes-outer-cont {
    min-height: 54px;
}

.loading-changes-cont {
    min-height: 54px;
}

.disclaimer-text {
    font-size: 13px;
    color: #969696;
}
.disclaimer-text-bigger {
    font-size: 14px;
    color: $gray-600;
    font-weight: 500;
}

// hidden tool tip date range on hover because of invalid date bug
.tooltip p.mb-0 {
    display: none !important;
}


// random
.highlight-green {
    background-color: greenyellow;
}
</style>
