<template>
    <div>
        <width-container>
            <div class="d-flex align-items-center justify-content-between page-title-and-buttons-cont">
                <page-header class="page-title mb-0">Colleague Profile</page-header>
                <div class="edit-save-button-cont text-right">
                    <button-bento color="dark" size="sm" @click="$router.push({name:'employee-directory'})">
                        <img src="@/assets/v2/icons/arrows/arrow-white.svg" class="me-2 save-white-icon"/>
                        <span>Back to team</span>
                    </button-bento>
                </div>
            </div>
            
            <div class="my-4">
                <card-general class="col-12 d-flex align-items-stretch">
                    <div class="row top-content-row">
                        <div class="name-and-title-col d-flex">
                            <div class="name-and-title-card flex-grow-1 my-0">
                                <div>
                                    <h3 class="mb-1">{{ fullName }}</h3>
                                    <h3 v-if="preferredName">({{ preferredName }})</h3>
                                    <h4 class="job-title mt-3 mb-1" v-if="jobTitle">{{ jobTitle }}</h4>
                                    <div class="self-description font-weight-500">{{ selfDescription }}</div>
                                </div>
                            </div>
                        </div>
                
                        <div class="profile-picture-image-col bg-white bento-border-radius d-flex align-items-stretch">
                            <div
                                class="p-0 position-relative my-0 flex-grow-1 profile-picture-card-general bento-border-radius"
                                :style="{ backgroundImage: 'url(' + profilePicture + ')' }"
                            ></div>
                        </div>
                    </div>
                </card-general>
            </div>
            
            <card-general class="mt-0">
                <section-header>Contact Details</section-header>
                <div>
                    <div class="row g-3">
                        <profile-info-card title="Cell Number" :value="cellNumber" @click="phoneColleagueHandler(cellNumber)" class="cursor-pointer"/>
                        <profile-info-card v-if="isBentoDemoCompany" title="Email" value="example@email.com" :editable="false" @click="emailColleagueHandler('example@email.com')" class="cursor-pointer"/>
                        <profile-info-card v-else title="Email" :value="email" :editable="false" @click="emailColleagueHandler(email)" class="cursor-pointer"/>
                        <profile-info-card title="Preferred Name" :value="preferredName" :full-width="true"/>
                        <profile-info-card title="Emergency Contact" :value="joinedOneLineEmergencyContact" :full-width="true"/>
                    </div>
                </div>
            </card-general>
            
            <card-general v-if="joinedOneLineAddress">
                <section-header>Home Address</section-header>
                <profile-info-card title="Address" :value="joinedOneLineAddress" :full-width="true"/>
            </card-general>
            
        </width-container>
    </div>
</template>

<script setup>
    import WidthContainer from "@/components/UI/v2/containers-cards-headers/WidthContainer.vue"
    import PageHeader from "@/components/UI/v2/containers-cards-headers/PageHeader.vue"
    import ButtonBento from "@/components/UI/v2/buttons/ButtonBento.vue"
    import CardGeneral from "@/components/UI/v2/containers-cards-headers/CardGeneral.vue"
    import {useRoute, useRouter} from "vue-router";
    import ProfileInfoCard from "@/components/employee-profile-directory/ui-components/ProfileInfoCard.vue"
    import SectionHeader from "@/components/UI/v2/containers-cards-headers/SectionHeader.vue"
    import {computed, onBeforeMount, ref} from "vue"
    import {useStore} from "vuex"
    import {toTitleCase} from "@/hooks/general/text-formatters"
    import {isProd} from "@/hooks/api/app-environment-origin"
    const route = useRoute()
    const router = useRouter()
    const store = useStore()

    const employeeDirectoryList = computed(() => store.getters.employeeDirectoryList)
    
    const employee = computed(() => employeeDirectoryList.value?.find(employee => employee.id == route.params.employeeId) ?? null)
    onBeforeMount(() => {
        if (!employee.value) {
            router.push({name: 'employee-directory'})
        }
    })
    
    const fullName = computed(() => (employee.value) ? toTitleCase(employee.value.user.first_name) + ' ' + employee.value.user.last_name : null)
    const jobTitle = computed(() => (employee.value) ? employee.value.job_title : null)
    // const email = employee.user.email
    // const idNumber = employee.id_number

    // editable fields
    const profilePictureStore = ref(employee.value?.profile_picture ?? null)
    if (employee.value && employee.value.profile_picture && employee.value.profile_picture.length) {
        profilePictureStore.value = profilePictureStore.value.replace('http://', 'https://')
    }
    const profilePicture = computed(() => profilePictureStore.value ?? require('@/assets/v2/placeholders/user-placeholder.png'))
    const preferredName = computed(() => employee.value?.preferred_name ?? null)
    const selfDescription = computed(() => employee.value?.self_description ?? null)
    const email = computed(() => employee.value?.user.email ?? null)
    const cellNumber = computed(() => employee.value?.cell_number ?? null)
    
    const employer = computed(() => store.getters.employer)
    const isBentoDemoCompany = computed(() => employer.value.name === 'Bento Demo' || (employer.value.id === 6 && isProd()) )
    
    // emergency contact
    const emergencyContact = computed(() => employee.value?.emergency_contact ?? null)
    const joinedOneLineEmergencyContact = computed(() => {
        if (!emergencyContact.value) return null
        const allValuesEmpty = Object.values(emergencyContact.value).every(value => value === null || value?.trim() === "");
        if (allValuesEmpty) return null

        let oneLiner = ''
        for (const key in emergencyContact.value) {
            let propValue = emergencyContact.value[key]?.trim()
            if (propValue) {
                oneLiner += emergencyContact.value[key] + `, `
            }
        }
        oneLiner = oneLiner?.trim()
        return oneLiner.endsWith(',') ? oneLiner.slice(0, -1) : oneLiner
    })
    
    
    // address
    const physicalAddress = computed(() => employee.value?.physical_address ?? null)
    const joinedOneLineAddress = computed(() => {
        if (!physicalAddress.value) {
            return null
        }
        let oneLiner = '';
        for (const key in physicalAddress.value) {
            let propValue = physicalAddress.value[key]?.trim()
            if (propValue && propValue != 'null') {
                oneLiner += physicalAddress.value[key] + ', '
            }
        }
        oneLiner = oneLiner?.trim()
        return oneLiner.endsWith(',') ? oneLiner.slice(0, -1) : oneLiner
    })

    const emailColleagueHandler = (email) => window.location.href = `mailto:${email}`
    const phoneColleagueHandler = (phone) => window.location.href = `tel:${phone}`
</script>

<style scoped lang="scss">
    @import "@/styles/global-scss/variables-and-mixins.scss";
    
    // title and buttons
    .page-title-and-buttons-cont {
        flex-wrap: wrap;
        margin-bottom: 24px;
        @media (min-width: 544px) {
            flex-wrap: nowrap;
        }
    }
    .page-title {
        flex: 0 0 100%;
        margin-top: 6px;
        @media (min-width: 544px) {
            flex: 1;
        }
    }
    .edit-save-button-cont {
        flex: 0 0 100%;
        margin-top: 8px;
        @media (min-width: 544px) {
            margin-top: 0;
            flex: 1;
            text-align: right;
        }
        .btn {
            margin-top: 4px;
        }
    }
    .save-white-icon {
        width: 17px;
        position: relative;
        top: -1px;
    }
    
    
    // image, name, self description
    .top-content-row {
        flex-wrap: wrap;
        @media (min-width: 460px) {
            flex-wrap: nowrap;
        }
    }
    .profile-picture-image-col {
        flex: 0 0 100%;
        max-width: 244px;
        height: 244px;
        order: 2;
        margin-top: 42px;
        @media (min-width: 460px) {
            flex: 1;
            order: 1;
            margin-top: 0;
        }
    }
    .profile-picture-card-general {
        box-shadow: none;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
    }
    .profile-img {
        border-radius: $border-radius;
    }
    .name-and-title-col {
        flex: 0 0 100%;
        order: 1;
        @media (min-width: 460px) {
            flex: 1;
            order: 2;
        }
    }
    .name-and-title-card {
        @media (min-width: 460px) {
            min-height: 242px;
        }
    }
    .job-title {
        color: $gradient-green-text-dark;
        font-weight: 600;
        font-size: 15px;
        margin-top: 2px;
        @include media-breakpoint-up(md) {
            font-size: 16px;
            white-space: nowrap;
        }
    }

    .self-description {
        color: $gradient-green-text-light;
    }
</style>