// auth getters
// not namespaced

import {isProd} from "@/hooks/api/app-environment-origin";

export default {
    authToken: state => state.authToken,

    userId: state => state.userId,

    actualUserId: state => {
        let actualUserId = state.actualUserId
        if (!actualUserId) {
            actualUserId = localStorage.getItem('actualUserId')
        }
        return actualUserId
    },

    isAuthenticated: (_, getters) => !!(getters.authToken && localStorage.getItem('authToken')),

    isUserAdmin: (_, getters) => {
        const userId = Number(getters.userId)
        // 1:Bryn, 2:Ross, 3:Claudia. 4:Dennis (App and Staging)
        let adminUserIDs = [1, 2, 3, 4, 105]
        if (isProd()) {
            adminUserIDs = [1, 2, 3, 4]
        }

        if (!getters.isUserBento) return false
        return !!(adminUserIDs.find(id => id === userId))
    },

    isUserBento: () => localStorage.getItem('isUserBento')
}
