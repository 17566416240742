// admin-super-user module actions
import {apiResponseHandler} from "@/hooks/api/api-helper-function"
import theApiCall from '@/hooks/api/the-api-call'

export default {

    // need to use this on 'All Employees' page
    async loadEmployeeList(context, payload) {
        if (!payload.forceRefresh && !context.getters.shouldUpdateEmployeeList) { return }

        const response = await theApiCall('employees/')
        const responseData = await apiResponseHandler(response, 'Could not fetch employee list for admin usr.')

        context.commit('setEmployeeList', responseData)
        context.commit('setLastEmployeeListFetchTimeStamp', { timestamp: new Date().getTime() })
    },

    async loadMinimalEmployeeList(context, payload) {
        if (!payload.forceRefresh && !context.getters.shouldUpdateMinimalEmployeeList) { return }
        if (!context.getters.isUserAdmin && !context.getters.isHrAdmin) { return }

        const response = await theApiCall('minimal_employees/')
        const responseData = await apiResponseHandler(response, 'Could not fetch minimal employee list for admin usr.')

        context.commit('setMinimalEmployeeList', responseData)
        context.commit('setLastMinimalEmployeeListFetchTimeStamp', { timestamp: new Date().getTime() })
    },

    async loadEmployerList(context, payload) {
        if (!payload.forceRefresh && !context.getters.shouldUpdateEmployerList) { return }
        if (!context.getters.isUserAdmin) { return }

        const response = await theApiCall('employers/');
        const responseData = await apiResponseHandler(response, 'Could not load employer list.')

        await context.commit('setEmployerList', responseData);
        await context.commit('setlastEmployerListFetchTimestamp', { timestamp: new Date().getTime() });
    },


    async loadEmployeeListWithLeaveApprovees(context, payload) {
        if (!payload.forceRefresh && !context.getters.shouldUpdateEmployeeListWithLeaveApprovees) { return }
        if (!context.getters.isUserAdmin && !context.getters.isHrAdmin) { return }

        const employeeList = context.getters.employeeList

        // Define the function isEmployeeLeaveApprover
        const isEmployeeLeaveApprover = (employeeList, employeeID) =>
            employeeList.some(employee =>
                employee.leave_approvers.some(approver => approver.id === employeeID)
            )

        // Use the function to filter the employeeList and map to desired properties
        const employeeLeaveApproverList = employeeList
            .filter(employee => isEmployeeLeaveApprover(employeeList, employee.id))
            .map(employee => ({id: employee.id}))

        const employees = []

        // Loop through the the employee list
        for (const employee of employeeList) {
            // Check if the employee is a leave approver
            if (employeeLeaveApproverList.some(approver => approver.id === employee.id)) {
                // Dispatch the loadLeaveApproveeList action for the employee
                const approvees = await context.dispatch('loadLeaveApproveeList', {otherUserId: employee.id, forceRefresh: true})
                // Augment the approvees to the employee
                employee.leave_approvees = approvees
            }
            // Add the employee to the employees array
            employees.push(employee)
        }

        await context.commit('setEmployeeListWithLeaveApprovees', employees)
        await context.commit('setlastEmployeeListWithLeaveApproveesFetch', { timestamp: new Date().getTime() })
    },

}