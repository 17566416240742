// hr admin getters

export default {

    // groups
    isHrAdmin: (_, getters) => getters.employee?.groups?.includes("HR Admin") || false,
    isHrAdminExFinancials: (_, getters) => getters.employee?.groups?.includes("HR Admin Ex. Financials") || false,

    // ** remember permissions can be assigned to a group or on an individual basis
    // individual permissions
    canViewEmployee: (_, getters) => getters.employee?.permissions?.includes("view_employee") || false,
    canChangeEmployee: (_, getters) => getters.employee?.permissions?.includes("change_employee") || false,
    canChangeEmployeeBankAccount: (_, getters) => getters.employee?.permissions?.includes("change_employee_bank_account") || false,
    canChangeEmployeeApprovers: (_, getters) => getters.employee?.permissions?.includes("change_employee_approvers") || false,
    canChangeEmployeeReimbursementApprovers: (_, getters) => getters.employee?.permissions?.includes("change_employee_reimbursement_approvers") || false,
    canChangeEmployeeLeaveApprovers: (_, getters) => getters.employee?.permissions?.includes("change_employee_leave_approvers") || false,

    // has any HR admin permission (used to show HR admin section on front-end)
    showTeamAdminInterface: (_, getters) => {
        return getters.isHrAdmin ||
            getters.isHrAdminExFinancials ||
            getters.canChangeEmployee ||
            getters.canChangeEmployeeBankAccount ||
            getters.canChangeEmployeeApprovers ||
            getters.canChangeEmployeeReimbursementApprovers ||
            getters.canChangeEmployeeLeaveApprovers
    },

}
