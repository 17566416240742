<template>
    <div>
        <width-container>
            <home-page-intro-summary v-if="false"/>

            <home-page-values-testing v-if="false"/>
            
            <home-section-benefits/>
            <home-section-reimbursements v-if="areReimbursements && isEnabledReimbursements"/>
            <home-section-simple-benefits-all/>
            <home-section-learn-more v-if="learningResourcesHomeApi"/>

            <footer-contact-help-card/>
            <div class="text-end disclaimer-text">V0.4.27</div>
        </width-container>
    </div>
</template>

<script setup>
    import HomeSectionBenefits from "../home-sections/HomeSectionBenefits"
    import HomeSectionSimpleBenefitsAll from "@/components/home-sections/HomeSectionSimpleBenefitsAll"
    import HomeSectionLearnMore from "../home-sections/HomeSectionLearnMore"
    import WidthContainer from "@/components/UI/v2/containers-cards-headers/WidthContainer"
    import HomeSectionReimbursements from "@/components/home-sections/HomeSectionReimbursements"
    import {isValidArray} from "@/hooks/general/type-and-value-checks"
    import {computed} from "vue"
    import {useStore} from "vuex"
    import HomePageIntroSummary from "@/components/home-sections/HomePageIntroSummary.vue"
    import FooterContactHelpCard from "@/components/layouts/footer/FooterContactHelpCard.vue"
    import HomePageValuesTesting from "@/components/home-sections/HomePageValuesTesting.vue";
    
    const store = useStore()
    
    const reimbursementAllowanceList = computed(() => store.getters.reimbursementAllowanceList)
    const isEnabledReimbursements = computed(() => store.getters.isEnabledReimbursements)
    const learningResourcesHomeApi = computed(() => store.getters.learningResourcesHomeApi)
    const areReimbursements = computed(() => isValidArray(reimbursementAllowanceList.value))
</script>