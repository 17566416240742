<template>
    <summary-card :classList="classList" view-more-link="benefits/retirement" view-more-text="View more">
        <template #title>Retirement</template>
        
        <template #subHeading>
            <span>{{ subHeading }}</span>
        </template>
        
        <template #cardText>
            <div v-html="cardText"></div>
        </template>
    </summary-card>
</template>

<script setup>
    import SummaryCard from "@/components/cards/SummaryCard"
    import {bentoCurrencyFormat} from "@/hooks/general/currency-number-formatters"
    import {computed} from "vue"
    import {useStore} from "vuex"
    import useInfoCard from "@/hooks/use-component/cards/info-card"
    
    const store = useStore()
    
    const benefit = store.getters.essentialBenefits.retirementAnnuity
    const {classList} = useInfoCard(benefit)
    
    const retirementFundContractObj = computed(() => store.getters.retirementFundContractObj)
    const hasRetirementFundContract = computed(() => store.getters.hasRetirementFundContract)
    const hasRetirementFundContractPlanned = computed(() => store.getters.hasRetirementFundContractPlanned)
    const areRetirementChangesSubmitted = computed(() => store.getters.areRetirementChangesSubmitted)
    
    
    // Retirement Text Options
    
    // 1. Already on a plan
    // 1.1 Where company covers the cost
    // 1.2 Where company also contribute
    // 1.3 Where only employees contribute
    
    // 2. Retirement - Not set up yet
    // 2.1 where company also contributes
    // 2.2 where only employee contributes
    const subHeading = computed(() => {
        if (hasRetirementFundContract.value) {
            return `${bentoCurrencyFormat(Number(retirementFundContractObj.value.employee_contribution) + Number(retirementFundContractObj.value.employer_contribution))} pm`
        } else if (areRetirementChangesSubmitted.value) {
            return `You have a submitted change`
        } else if (hasRetirementFundContractPlanned.value) {
            return `You have a draft contract`
        } else {
            return `No fund selected`
        }
    })
    
    const cardText = computed(() => {
        if (hasRetirementFundContract.value) {
            return `<p>Your are currently contributing <span>${bentoCurrencyFormat(Number(retirementFundContractObj.value.employee_contribution))} pm</span> towards your retirement. Your employer is contributing <span>${bentoCurrencyFormat(Number(retirementFundContractObj.value.employer_contribution))} pm.</span></p>
              <p>Learn more about your contribution options, the ins and outs of saving for retirement and the tax benefits associated with retirement funds.</p>`
        } else {
            return noCurrentContract
        }
    })
    // const companyContributes = computed(() => `<p>New retirement copy here...</p>`)
    
    const noCurrentContract = `<p>Start saving towards your retirement today - it's never too early.</p>
              <p>Learn more about your contribution options, the ins and outs of saving for retirement and the tax benefits associated with retirement funds.</p>`
</script>