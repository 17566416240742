import store from "@/store"


// includes all calls on app init
export async function loadAllApiData(forceRefresh = false) {
    try {
        await loadCoreData(forceRefresh)
        await loadAllOtherData(forceRefresh)
        await loadDependantData(forceRefresh)
    } catch (error) {
        store.commit('setShowApiErrorModal', true)
        store.commit('setApiErrorMessage', error.message)
    }
}

export async function loadCoreData(forceRefresh = false) {
    try {
        await Promise.all([
            store.dispatch('loadEmployee', {forceRefresh: forceRefresh}),
            store.dispatch('loadEmployer', {forceRefresh: forceRefresh}),
            store.dispatch('loadContentCards', {forceRefresh: forceRefresh}),
            store.dispatch('loadSimpleBenefitsList', {forceRefresh: forceRefresh}),
        ])
    } catch (error) {
        store.commit('setShowApiErrorModal', true)
        store.commit('setApiErrorMessage', error.message)
    }
}


// requires employer to be loaded, so we can check what calls to make based on what is enabled on the employer object
export async function loadAllOtherData(forceRefresh = false) {
    try {
        const promises = []
        // static content (tax tables)
        promises.push(store.dispatch('loadStaticContent', {forceRefresh: forceRefresh}))
        // employee data and profile
        promises.push(store.dispatch('loadPayslipsList', {forceRefresh: forceRefresh}))
        promises.push(store.dispatch('loadBankAccountChangeRequestList', {forceRefresh: forceRefresh}))
        promises.push(store.dispatch('loadEmployeeDirectoryList', {forceRefresh: forceRefresh}))
        // leave
        if (store.getters.isEnabledLeave) {
            promises.push(store.dispatch('loadLeaveApproveeList', {forceRefresh: forceRefresh}))
            promises.push(store.dispatch('loadLeaveRequestList', {forceRefresh: forceRefresh}))
        }
        // reimbursements
        if (store.getters.isEnabledReimbursements) {
            promises.push(store.dispatch('loadReimbursementAllowanceList', {forceRefresh: forceRefresh}))
            promises.push(store.dispatch('loadReimbursementClaimList', {forceRefresh: forceRefresh}))
            promises.push(store.dispatch('loadReimbursementApproveeList', {forceRefresh: forceRefresh}))
        }
        // medical
        if (store.getters.isEnabledMedicalAid) {
            promises.push(store.dispatch('loadMedicalAidPlanList', {forceRefresh: forceRefresh}))
            promises.push(store.dispatch('loadEmployeeMedicalAidsList', {forceRefresh: forceRefresh}))
            promises.push(store.dispatch('loadMedicalAidPlanAddonList', {forceRefresh: forceRefresh}))
        }
        // retirement
        if (store.getters.isEnabledRetirement) {
            promises.push(store.dispatch('loadRetirementFundList', {forceRefresh: forceRefresh}))
            promises.push(store.dispatch('loadEmployeeRetirementFundContractList', {forceRefresh: forceRefresh}))
            promises.push(store.dispatch('loadRetirementFundContractChangeRequestList', {forceRefresh: forceRefresh}))
        }
        await Promise.all(promises)
    } catch (error) {
        store.commit('setShowApiErrorModal', true)
        store.commit('setApiErrorMessage', error.message)
    }
}

// requires loadCoreData and LoadAllOtherData to be called first
export async function loadDependantData(forceRefresh = false) {
    try {
        const promises = []
        // medical
        if (store.getters.isEnabledMedicalAid) {
            promises.push(store.dispatch('loadMedicalAidContractChangeRequestList', {forceRefresh: forceRefresh}))
        }
        // retirement
        // loaded at end of loadRetirementFundContractChangeRequestList(context, payload) in retirement-contract-actions.js // should do the same for medical aid
        await Promise.all(promises)
    } catch (error) {
        store.commit('setShowApiErrorModal', true)
        store.commit('setApiErrorMessage', error.message)
    }
}

// requires loadCoreData, LoadAllOtherData, loadDependantData (i.e. loadAllApiData) to to be called first
// but doesn't stop the main app from loading
export async function loadDataInBackground(forceRefresh = false) {
    try {
        const promises = []
        // reimbursements (requires loadReimbursementApproveeList to be loaded)
        if (store.getters.isEnabledReimbursements) {
            promises.push(store.dispatch('loadReimbursementAllowanceListAllApprovees', {forceRefresh: forceRefresh}))
        }
        // leave
        if (store.getters.isEnabledLeave) {
            promises.push(store.dispatch('isFutureLeaveBalanceEnabled'))
        }
        await Promise.all(promises)
    } catch (error) {
        store.commit('setShowApiErrorModal', true)
        store.commit('setApiErrorMessage', error.message)
    }
}


export async function setAcceptTerms() {
    try {
        await store.dispatch('setAcceptTerms')
    } catch (error) {
        store.commit('setShowApiErrorModal', true)
        store.commit('setApiErrorMessage', error.message)
    }
}


// bento admin calls - full list of employers and employees
export async function loadBentoAdminData(forceRefresh = false) {
    try {
        await Promise.all([
            store.dispatch('loadEmployerList', {forceRefresh: forceRefresh}),
            // store.dispatch('loadEmployeeList', {forceRefresh: forceRefresh}) -> Don't need to load the full list anymore, only load on All Employees Page
            store.dispatch('loadMinimalEmployeeList', {forceRefresh: forceRefresh}),
        ])
    } catch (error) {
        store.commit('setShowApiErrorModal', true)
        store.commit('setApiErrorMessage', error.message)
    }
}

// hr manager calls - full list of employees (from their company)
export async function loadHrManagerData(forceRefresh = false) {
    try {
        await store.dispatch('loadEmployeeList', {forceRefresh: forceRefresh})
    } catch (error) {
        store.commit('setShowApiErrorModal', true)
        store.commit('setApiErrorMessage', error.message)
    }
}


export async function testGetFutureLeaveBalanceFunctionality() {
    try {
        const futureDate = new Date()
        futureDate.setMonth(futureDate.getMonth() + 1)
        const formattedFutureDate = futureDate.toISOString().split('T')[0]
        const futureLeaveBalance = await store.dispatch('getFutureLeaveBalance', {date: formattedFutureDate})
        console.log(futureLeaveBalance)
        console.log("Future leave balance functionality is supported.")
    } catch (error) {
        console.warn("Future leave balance functionality is not supported.") // Custom error message
    }
}