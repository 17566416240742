<template>
    <div>
        <div v-if="(!(isHrAdmin || isHrAdminExFinancials || isLeaveApprover) && isFutureLeaveBalanceEnabled)">
            <width-container>
                <bento-alert static color="danger">You do not have permissions to access this page.</bento-alert>
            </width-container>
        </div>

        <div v-else>

            <div v-if="isLoading">
                <loading-full-screen v-model="isLoading" loadingText="Loading employees ..."/>
            </div>

            <width-container v-else>

                <div>
                    <div>
                        <h1 class="mb-4 pb-2">
                            <span class="d-inline-block me-4">My Team</span> <span
                            class="d-inline-block me-3 title-chevron"><img src="@/assets/v2/icons/chevron.svg"
                            alt="Chevron right"/></span> <span
                            class="d-inline-block font-weight-400">Check Annual Leave Balance</span>
                        </h1>

                        <card-general>
                            <div>
                                <section-header>Select an employee</section-header>
                                <div class="mt-n4 font-size-14 mb-4 pb-2 fw-500 text-gray-main">
                                    Find out a team member's future annual leave balance for a selected date
                                </div>

                                <div class="d-flex align-items-center flex-wrap flex-md-nowrap select-and-output-cont">
                                    <div class="pe-md-3">
                                        <MDBSelect
                                            ref="employeeSelect"
                                            v-model:options="optionsEmployeeList"
                                            v-model:selected="selectedEmployeeID"
                                            filter
                                            :preselect="true"
                                            label="Employee" size="lg" placeholder="Select employee ..."
                                            class="mb-3"
                                        />
                                    </div>
                                    <div class="ps-md-3">
                                        <reporting-lines-employee-info-block :employee="selectedEmployeeObj" v-if="selectedEmployeeID && selectedEmployeeObj"/>
                                        <div v-else class="fw-500 my-3">No employee selected.</div>
                                    </div>
                                </div>
                            </div>

                            <div class="mt-5 bg-primary-50 px-2 px-sm-3 px-md-5 py-4 bento-border-radius"
                                id="select-a-date-cont" v-if="selectedEmployeeID">
                                <section-header>Select a date</section-header>
                                <div class="mt-n4 font-size-14 mb-4 pb-2 fw-500 text-gray-main">Get the future annual
                                    leave balance for the selected date
                                </div>

                                <div class="d-flex align-items-stretch flex-wrap flex-md-nowrap select-and-output-cont">
                                    <div class="pe-md-3">
                                        <div id="leave-interface-2" v-if="!isLoadingCalendar">
                                            <div class="flex-grow-1 pe-md-4 calendar-cont">
                                                <v-date-picker
                                                    is-expanded
                                                    v-model="selectedDate"
                                                    mode="date"
                                                    :min-date='new Date()'
                                                    :model-config="modelConfig"
                                                    :attributes="attributesComputed"
                                                />
                                            </div>
                                        </div>

                                        <div class="loading-changes-cont mb-3 max-w-380" v-if="isLoadingCalendar">
                                            <loading-small
                                                v-model="isLoadingCalendar"
                                                parentSelector=".loading-changes-cont"
                                                loadingText="Updating calendar ..."/>
                                        </div>

                                        <div class="disclaimer-text" v-if="loadOtherEmployeeRequestsError">Loading selected employee leave requests ... <br>{{ loadOtherEmployeeRequestsErrorNotice }}</div>
                                    </div>
                                    <div
                                        class="ps-md-3 d-flex flex-column justify-content-end flex-grow-1 pe-md-4 future-leave-balance-cont order-2 order-md-1">

                                        <div class="loading-changes-cont mb-3 max-w-380" v-if="isSubmittingRequest">
                                            <loading-small
                                                v-model="isSubmittingRequest"
                                                parentSelector=".loading-changes-cont"
                                                :loadingText="loadingTextMessage"/>
                                        </div>

                                        <div v-if="apiResponseError" class="max-w-360">
                                            <bento-alert class="mb-3" color="danger" static dismiss
                                                v-model="apiResponseError">
                                                {{ apiResponseErrorNotice }}
                                            </bento-alert>
                                        </div>

                                        <div v-if="balanceDisplayObj" class="max-w-360 mb-1">
                                            <bento-alert color="info" static dismiss v-model="balanceNotice"
                                                @click="clearFutureBalanceObjHandler">
                                                <div class="fw-500 font-size-16 mb-2">Future Leave Balance</div>
                                                <div class="font-size-14">
                                                    <div class="d-flex">
                                                        <div class="label">Balance date:</div>
                                                        <div class="fw-500">{{ longDateFormat(balanceDisplayObj.date) }}
                                                        </div>
                                                    </div>
                                                    <div class="d-flex">
                                                        <div class="label">Accrued leave:</div>
                                                        <div class="fw-500"
                                                            :class="{'text-danger': Number(balanceDisplayObj.balance) < 0}">
                                                            {{ balanceDisplayObj.balance }} days
                                                        </div>
                                                    </div>
                                                    <div class="d-flex">
                                                        <div class="label">Leave type:</div>
                                                        <div class="fw-500">Annual</div>
                                                    </div>
                                                    <div class="d-flex">
                                                        <div class="label">Employee:</div>
                                                        <div class="fw-500">{{ selectedEmployeeName }}</div>
                                                    </div>
                                                </div>
                                            </bento-alert>
                                        </div>

                                        <div class="pb-5">
                                            <button-bento
                                                :disabled="!selectedDate || balanceDisplayObj"
                                                color="primary" @click="getFutureLeaveBalanceHandler"
                                                class="get-balance-btn mb-2"
                                            >
                                                Get future leave balance
                                            </button-bento>
                                            <div v-if="!selectedDate"
                                                class="fw-500 mb-4 font-size-13 text-warning max-w-360">
                                                Please select a date to get a future leave balance.
                                            </div>

                                            <div class="fw-500 font-size-13 lh-sm text-gray-main mt-3 max-w-360">
                                                This takes into account an employee's leave accrual until the selected date and future approved leave requests.
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </card-general>
                    </div>
                </div>
            </width-container>
        </div>
        <div class="font-size-11 text-gray-50" v-if="false">
            <div><pre>HR: {{ isHrAdmin }} :: HRx{{ isHrAdminExFinancials }} :: AdAll: {{ isAdminOrCanViewAll }}</pre></div>
        </div>
    </div>
</template>

<script setup>
import WidthContainer from "@/components/UI/v2/containers-cards-headers/WidthContainer"
import {ref, computed, watch} from "vue"
import {useStore} from "vuex"
import BentoAlert from "@/components/UI/v2/alerts/BentoAlert"
import LoadingFullScreen from "@/components/UI/v2/loading/LoadingFullScreen"
import CardGeneral from "@/components/UI/v2/containers-cards-headers/CardGeneral.vue"
import ButtonBento from "@/components/UI/v2/buttons/ButtonBento.vue"
import {MDBSelect} from "mdb-vue-ui-kit"
import SectionHeader from "@/components/UI/v2/containers-cards-headers/SectionHeader.vue"
import ReportingLinesEmployeeInfoBlock
    from "@/components/manager/team-admin/reporting-lines/ReportingLinesEmployeeInfoBlock.vue"
import {loadHrManagerData} from "@/hooks/api/api-dispatch-calls"
import {longDateFormat} from "@/hooks/general/date-helpers"
import {isValidArray} from "@/hooks/general/type-and-value-checks"
import publicHolidays from "@/data/public-holidays/public-holidays"
import LoadingSmall from "@/components/UI/v2/loading/LoadingSmall.vue"
import {toOneFirstName} from "@/hooks/general/user-name-formatters"
import theApiCall from "@/hooks/api/the-api-call";
import {apiResponseHandler} from "@/hooks/api/api-helper-function";

const store = useStore()

// page permissions
const isLeaveApprover = computed(() => store.getters.isLeaveApprover)

const isHrAdmin = computed(() => store.getters.isHrAdmin)
const isHrAdminExFinancials = computed(() => store.getters.isHrAdminExFinancials)
const isAdminOrCanViewAll = computed(() => isHrAdmin.value || isHrAdminExFinancials.value)

const isFutureLeaveBalanceEnabled = computed(() => store.getters.isFutureLeaveBalanceEnabled) // must stay computed, test call happens in BG
const balanceDisplayObj = ref(null)

const balanceNotice = ref(true)


// 1. employee select
const leaveApproveeList = computed(() => store.getters.leaveApproveeList) // for the regular leave approver - /employees/2/leave_approvees

const selectedEmployeeID = ref(null)
const selectedEmployeeObjLeaveRequestsList = ref(null)
const selectedEmployeeObj = computed(() => {
    if (!isAdminOrCanViewAll.value) {
        return leaveApproveeList.value.find(employee => employee.id === selectedEmployeeID.value)
    }
    const selectedEmployeeObjWithoutLeaveRequests = employeeDirectoryList.value.find(employee => employee.id === selectedEmployeeID.value)
    const selectedEmployeeObjWithLeaveRequests = {
        ...selectedEmployeeObjWithoutLeaveRequests,
        leave_requests: selectedEmployeeObjLeaveRequestsList.value
    }
    return selectedEmployeeObjWithLeaveRequests
})
const selectedEmployeeName = computed(() => isAdminOrCanViewAll.value ? `${toOneFirstName(selectedEmployeeObj.value?.user.first_name)} ${selectedEmployeeObj.value?.user.last_name}` : `${selectedEmployeeObj.value?.name}`)


const employeeSelect = ref(null) // just so we can use methods on it

const employeeDirectoryList = computed(() => store.getters.employeeDirectoryList)

// EmployeeList for the select dropdown. Either just approvees, or all employees if permissions granted
const optionsEmployeeList = computed(() => {
    const arr = []
    // check if they can view all leave balances or if they are just an approver
    const employees = (isAdminOrCanViewAll.value) ? employeeDirectoryList.value : leaveApproveeList.value

    for (const employee of employees) {
        let isSelected = (employee.id == selectedEmployeeID.value)
        arr.push({
            selected: isSelected,
            text: (isAdminOrCanViewAll.value) ? `${toOneFirstName(employee.user.first_name)} ${employee.user.last_name}` : `${employee.name}`,
            value: Number(employee.id)
        })
    }
    return arr
})

// 2. V-calendar for an employee
const selectedDate = ref(null)
const modelConfig = ref({
    type: 'string',
    mask: 'YYYY-MM-DD',
})

// 3. Get future leave balance for the selected date
// sends a get request to const response = await theApiCall(`employees/${userApiId}/future_leave_balances/?date=${payload.date}`)
const apiResponseError = ref(false)
const apiResponseErrorNotice = ref(false)
const isSubmittingRequest = ref(false)
const loadingTextMessage = ref('Getting future leave balance ...')
const getFutureLeaveBalanceHandler = async () => {
    balanceDisplayObj.value = null
    if (!selectedDate.value) return

    isSubmittingRequest.value = true

    let futureLeaveBalanceList
    try {
        futureLeaveBalanceList = await store.dispatch('getFutureLeaveBalance', {
            date: selectedDate.value,
            employeeID: selectedEmployeeID.value
        })
    } catch (error) {
        apiResponseError.value = true
        apiResponseErrorNotice.value = error.message
        isSubmittingRequest.value = false
        return
    }
    const futureLeaveBalance = futureLeaveBalanceList.find(balance => balance.id === annualLeaveObj.value.id)
    balanceDisplayObj.value = {
        date: selectedDate.value,
        balance: futureLeaveBalance.balance,
        name: futureLeaveBalance.name,
    }
    isSubmittingRequest.value = false
}


// 3. Get leave attributes for an employee

// a. Get leave request list for the selected employee
const leaveRequestList = computed(() => {
    return selectedEmployeeObj.value.leave_requests
})

const annualLeaveObj = computed(() => store.getters.annualLeaveObj)

const leaveRequestsForThisType = computed(() => {
    if (!isValidArray(leaveRequestList.value)) {
        return false
    }
    const unsorted = leaveRequestList.value
    const sortedByDate = unsorted.sort((a, b) => new Date(a.start_date) - new Date(b.start_date))
    const leaveTypeId = Number(annualLeaveObj.value.id)
    return sortedByDate.filter(request => Number(request.leave_type) === leaveTypeId)
})

const attributesComputed = computed(() => {
    let submittedAndApproved = null
    if (leaveRequestsForThisType.value) {
        submittedAndApproved = leaveRequestsForThisType.value.filter(request => request.state === "submitted" || request.state === "approved")
    }

    let attributes = []
    if (submittedAndApproved) {
        attributes = submittedAndApproved.map((request) => ({
            dates: {
                start: new Date(request.start_date),
                end: new Date(request.end_date),
            },
            highlight: "teal",
        }))
    }

    // today
    attributes.unshift({
        key: 'today',
        bar: {
            color: 'yellow',
            style: {
                height: '4px',
                width: '65%',
            },
        },
        dates: new Date(),
    })

    // this year and next work annirversary
    const dateOfEmployment = store.getters.employee.date_of_employment
    const givenDate = new Date(dateOfEmployment)
    const month = givenDate.getMonth()  // JavaScript months are 0-based
    const day = givenDate.getDate()

    const currentYear = new Date().getFullYear()
    const nextYear = currentYear + 1

    const sameDayThisYear = new Date(currentYear, month, day)
    const sameDayNextYear = new Date(nextYear, month, day)
    const dates = [sameDayThisYear, sameDayNextYear]
    dates.forEach(date => {
        attributes.unshift({
            key: "anniversary",
            highlight: "pink",
            dates: date,
            popover: {
                label: 'Work Anniversary'
            }
        })
    })

    attributes.push(...publicHolidays)
    return attributes
})

const isLoadingCalendar = ref(false)
const loadOtherEmployeeRequestsError =  ref(false)
const loadOtherEmployeeRequestsErrorNotice = ref(false)
const employeeSelectChangeHandler = async () => {
    isLoadingCalendar.value = true
    selectedEmployeeObjLeaveRequestsList.value = null
    apiResponseError.value = false
    apiResponseErrorNotice.value = false
    loadOtherEmployeeRequestsError.value = false
    loadOtherEmployeeRequestsErrorNotice.value = false

    if (!isAdminOrCanViewAll.value) {
        isLoadingCalendar.value = false
        return
    }

    let response, responseData
    try {
        response = await theApiCall(`employees/${selectedEmployeeID.value}/leave_requests/`)
        responseData = await apiResponseHandler(response, 'Could not load employee leave requests.', 'Leave requests not found for this employee. [404]')
    } catch (error) {
        loadOtherEmployeeRequestsError.value = true
        loadOtherEmployeeRequestsErrorNotice.value = error.message
        isLoadingCalendar.value = false
        return
    }

    selectedEmployeeObjLeaveRequestsList.value = responseData
    isLoadingCalendar.value = false
}


const clearFutureBalanceObjHandler = () => balanceDisplayObj.value = null

// watchers
watch(selectedDate, () => {
    balanceDisplayObj.value = null
})
watch(selectedEmployeeID, () => {
    clearFutureBalanceObjHandler()
    employeeSelectChangeHandler()
})

// On component creation / load
const isLoading = ref(true)
const onCreated = async () => {
    isLoading.value = true
    await loadHrManagerData()
    isLoading.value = false
}
onCreated()
</script>

<style scoped lang="scss">
@import "@/styles/global-scss/variables-and-mixins.scss";

.select-and-output-cont {
    > div {
        flex: 0 0 100%;
        @include media-breakpoint-up(md) {
            flex: 0 0 50%;
        }
    }
}

#select-a-date-cont {
    border: 1px solid rgba($card-border-color, .75);
}

:deep(#leave-interface-2) {
    .vc-container {
        background: transparent;
        border: none;
    }

    .vc-title {
        font-weight: 500;
        font-size: 16px;
    }

    .vc-header {
        padding-bottom: 10px;
    }

    .vc-day {
        min-height: 36px;
    }
}

.max-w-360 {
    width: 356px;
    max-width: 100%;
}
.max-w-380 {
    width: 380px;
    max-width: 100%;
}

.label {
    flex: 0 0 120px;
}

.get-balance-btn {
    width: 354px;
    max-width: 100%;
    min-width: auto;
}
</style>